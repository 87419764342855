<template>
  <div>
    <van-nav-bar
        title="合同新建"
        left-arrow
        :border="false"
        @click-left="$router.back()"
    />
    <div class="edit-area" style="padding: 24px 12px 15px 12px">
      <div class="acea-row row-middle item">
        <div class="font-size-14-500 font-color-444">合同编号：</div>
        <div>
          <van-field v-model="form.contractNo" placeholder="请输入合同编号"/>
        </div>
      </div>
      <div class="acea-row row-middle item">
        <div class="font-size-14-500 font-color-444">合同名称：</div>
        <div>
          <van-field v-model="form.contractNo" placeholder="请输入合同名称"/>
        </div>
      </div>
      <div class="acea-row row-middle item">
        <div class="font-size-14-500 font-color-444">收款单号：</div>
        <div>
          <van-field v-model="form.contractNo" placeholder="请输入收款单号"/>
        </div>
      </div>
      <div>
        <div class="font-size-14-500 font-color-444" style="margin-bottom: 13px">合同备注：</div>
        <van-field
            v-model="form.remark"
            rows="1"
            type="textarea"
            style="background: #F5F5F5;width: 100%;margin-left: 0;padding: 5px"
            placeholder="请输入留言"
        />
      </div>
    </div>
    <div class="edit-area" style="padding: 17px 12px;margin-top: 10px">
        <div class="acea-row row-between row-middle" style="margin-bottom: 18px">
          <div class="font-size-14-500 font-color-444">产品信息：</div>
          <div class="acea-row row-middle">
            <van-icon name="add" color="#168BF6" size="16"/>
            <div class="font-size-12-400 font-color-333" style="margin-left: 3px">添加产品</div>
          </div>
        </div>
        <a-table :data-source="dataSource" :columns="columns"></a-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "add",
  data() {
    return {
      columns:[
        {
          title: 'name',
          dataIndex: 'name',
        },
        {
          title: 'age',
          dataIndex: 'age',
        },
      ],
      dataSource:[],
      form: {
        contractNo: '',
        remark:''
      }
    }
  }
}
</script>

<style>
.app {
  background: #F5F5F5;
}
</style>

<style scoped lang="less">
/deep/ .van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__text {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.van-nav-bar__right {
  div {
    color: #fff !important;
  }
}

/deep/ .van-cell {
  margin-left: 13px;
  padding: 0px;
}

.edit-area {
  background: #FFFFFF;
  width: 100%;
  height: auto;

  .item {
    margin-bottom: 12.5px
  }
}
</style>
