<template>
  <div>
    <Popup
      v-model:show="userDetail"
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: 'auto' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">新建客户</div>
      <van-cell-group class="data-group">
        <div>
          <van-field
            label="姓名:"
            required
            placeholder="请输入姓名"
            v-model="form.name" />
          <van-field
              label="电话:"
              required
              placeholder="请输入电话"
              v-model="form.phoneNumber" />
          <van-field
              label="邮箱:"
              :placeholder="`请输入邮箱`"
              v-model="form.email" />
          <van-field
              label="微信:"
              :placeholder="`请输入微信`"
              v-model="form.wechat" />
          <van-field
              label="QQ:"
              :placeholder="`请输入QQ`"
              v-model="form.qq" />
          <van-field
              label="地址:"
              :placeholder="`请输入地址`"
              v-model="form.address" />
<!--          <van-field-->
<!--              label="信息来源:"-->
<!--              :placeholder="`请输入信息来源`"-->
<!--              v-model="form.value" />-->
<!--          <van-field-->
<!--              v-model="form.remark"-->
<!--              rows="1"-->
<!--              autosize-->
<!--              label="备注信息"-->
<!--              type="textarea"-->
<!--              placeholder="请输入备注"-->
<!--          />-->
        </div>
      </van-cell-group>
      <div style="padding:0 20px 15px 20px;">
        <van-button type="info" block @click="modifyUserBtn">添加客户</van-button>
      </div>
    </Popup>
    <selectTime ref="selectTime" />
    <!--    选择弹窗-->
    <setDetailOption ref="setDetailOption" @change="receiveOption" />
    <!-- 客户合并弹窗  -->
    <contactMerge ref="contactMerge"></contactMerge>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ContactApi } from '@/api/contact'
import { Popup, Divider, DatetimePicker, Toast } from 'vant'
import selectTime from '../../room/components/selectTime'
import setDetailOption from './setDetailOption'
import contactMerge from "@/views/contact/components/contactMerge";
export default {
  components: {
    Popup, Divider, DatetimePicker, selectTime, setDetailOption,contactMerge
  },
  data () {
    return {
      userDetail: false,
      checkRowIndex: '',
      form:{
        name:'',
        phoneNumber:'',
        email:'',
        wechat:'',
        qq:'',
        address:'',
      }
    }
  },
  methods: {
    receiveOption (e) {
      this.detail[this.checkRowIndex].value = e
    },
    // 弹窗选项
    setOptionCont (item, index) {
      this.checkRowIndex = index
      this.$refs.setDetailOption.show(item)
    },
    modifyUserBtn () {
      if(!this.form.name){
        Toast('姓名为必填项');
        return;
      }
      if(!this.form.phoneNumber){
        Toast('手机号为必填项');
        return;
      }
      // this.$refs.contactMerge.show()
      ContactApi.customerAdd(this.form).then(res => {
        Toast('添加成功')
        this.userDetail = false
        this.$emit('change', true)
      })
    },
    show (contactDetailInfo) {
      this.userDetail = true
    },
  }
}
</script>
<style lang="less" scoped>
.user_page{
  padding: 0 35px 0 35px;
  color: #333;
}
.user_flex{
  display: flex;
  justify-content:space-between;
}
.data-group {
  padding: 10px 0 30px 0;
  margin-top: 20px;
}
.picture-wrapper,.check-wrapper {
  align-items: center;
  display: flex;
  padding: 0 16px;
  .title {
    max-width: 400px;
    min-width: 200px;
    font-weight: 400;
    color: #919191;
  }
  .check-text {
    font-weight: 400;
    line-height: 50px;
  }
  .picture {
    width: 128px;
    height: 128px;
    margin-left: 20px;
  }
  .check-group {
    margin-left: -100px;
    .check-item {
      margin-left: 10px;
    }
  }
  .check-group-row{
  }
  .dropdown-menu {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: -20px;
    height: 80px;
  }
}
.upload {
  color: #1989fa;
}
.row_option{
  margin-left: -100px;
  width: 270px;
  height: 35px;
  border: 1px solid #cccccc;
  //line-height: 55px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.option-tip{
  color: #919191;
}
.option-value{

}
</style>
