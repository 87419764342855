<template>
  <div class="component">
    <van-popup v-model="show" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          :title="showTime"
          @cancel="show = false"
          @confirm="choseDate"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      show: false,
      currentDate: '',
      date: new Date(),
      index: 0,
      type:0,
      // showtime
      showTime: '时间选择',
      begin: ''
    }
  },
  watch: {},
  methods: {
    $show(i,type) {
      this.show = true
      this.index = i
      this.type = type
    },
    choseDate(e) {
      this.show = false
      this.$emit('change', moment(e).format("YYYY-MM-DD"),this.index,this.type)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
}
</style>
