<template>
  <div>
    <div class="index">
      <div class="select">
        <!--      <div style="white-space: nowrap;">-->
        <!--        团队-->
        <!--        <a-icon type="swap"/>-->
        <!--      </div>-->
        <van-search
            v-model="serchVale"
            shape="round"
            placeholder="搜索"
            @search="search"
            @clear="clear"
        />
        <!--          :readonly="!sreenw"-->
        <van-icon v-if="sreenw" @click="routerPusht" style="font-size: 20px" name="filter-o"/>
      </div>

      <van-tabs v-model="actives" title-active-color="#1890ff" color="#1890ff" background="#f1f2f5">
        <van-tab title="客户">
          <div class="customer">
            <div class="total"
                 style="font-weight: 550;display: flex;align-items: center;justify-content: space-between">
              <div>
                共{{ pages.total }}个客户
                <span v-if="showReset" @click="resetFilter" style="color: #2B98FF">重置筛选</span>
              </div>
              <div style="display: flex">
                <a @click="$refs.contactAdd.show()" v-if="!checkMode"
                   style="margin-right: 5px;font-weight: normal">添加客户</a>
                <a @click="checkMode = true" v-if="!checkMode" style="font-weight: normal">批量打标签</a>
                <a @click="checkMode = false" v-else style="font-weight: normal">取消</a>
              </div>
            </div>
            <div class="list">
              <van-checkbox-group icon-size="15px" v-model="resultCustomer" ref="checkboxGroup">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                  <div class="choseItem"
                       style="margin-top: 10px;border-radius: 6px;border: 1px solid transparent;box-shadow: 0px 2px 6px 0px rgba(110,115,126,0.1);"
                       v-for="(item, index) in userList">
                    <div class="list-item">
                      <van-checkbox v-if="checkMode" style="margin-right: 5px" :name="index"></van-checkbox>
                      <img @click="routerPush('/contact/detail?contactId='+item.id)" class="avater" :src="item.avatar"
                           alt="">
                      <div @click="routerPush('/contact/detail?contactId='+item.id)" class="mindel">
                        <div class="item">
                          <div style="display: flex;align-items: center">
                            <div class="name">{{ item.name }}</div>
                            <span v-if="item.wechats.length > 0">@微信</span>
<!--                            <van-tag color="rgba(22, 139, 246, 0.1)" text-color="#168BF6" style="height: 22px;font-size: 12px;zoom: 0.83" round >{{item.topicFollowStatus}}</van-tag>-->
<!--                            <van-tag color="rgba(246, 112, 22, 0.1)" text-color="#F67016" style="height: 22px;font-size: 12px;zoom: 0.83" round >有待办!</van-tag>-->
                          </div>
                          <div>
                            <div class="line2">
                              最近跟进：{{ !item.followRecord.content ? '无' : item.followRecord.content }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <img class="news" v-if="item.phoneNumbers.length == 0"
                           src="../../assets/images/customer/phone_nobind.png">
                      <img class="news" v-if="item.phoneNumbers.length > 0" @click="openManyPopup(item,1)"
                           src="../../assets/images/customer/phone_bind.png">
                      <img class="news" v-if="item.wechats.length == 0" style="margin-left: 10px"
                           @click="openManyPopup(item,2)" src="../../assets/images/customer/wechat_nobind.png">
                      <img class="news" v-if="item.wechats.length > 0" style="margin-left: 10px"
                           @click="openManyWechatPopup(item)" src="../../assets/images/customer/wechat_bind.png">
                    </div>
                    <div class="bottom">
                      <div style="width: 100%;height: 1px;color: #EEEEEE;margin: 0 20px"></div>
                      <div class="bottom-list">
                        <div>客户动态：</div>
                        <div style="width: 80%;word-break: break-all;">{{ item.customerTrack.content ? item.customerTrack.content : '无' }}</div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </van-checkbox-group>
            </div>
          </div>
        </van-tab>
        <van-tab title="客户群">
          <van-list
              v-model="loading"
              :finished="true"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div class="groups">
              <div class="total"
                   style="font-weight: 550;display: flex;align-items: center;justify-content: space-between">
                共{{ baseList.length }}个群聊
              </div>
              <div v-for="(item,index) in baseList" :key="index" class="group">
                <div class="flex-row justify-start info">
                  <div class="col-1">
                    <div class="flex-row"><img
                        src="../../assets/images/avatar-room.svg"
                        class="group-cover">
                      <div class="flex-col align-left">
                        <div class="flex-row"><span class="bold col-1">{{
                            item.name
                          }}<span>({{ item.total }})</span></span>
                          <span></span>
                        </div>
                        <div class="group-owner-text">
                          <span>群主：</span> <span class="qw-open-data __qwOpenData__" style=""> {{
                            item.ownerName
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a-icon :data-clipboard-text="item.name" @click="copy" class="coopy" type="copy"
                          style="font-size: 14.63px;color: #c7c7c7;"/>
                </div>
                <div class="data flex-row">
                  <!--                <div class="col-1"><span>{{item.createTime}}</span></div>-->
                  <!--                                <div class="col-1"><span><label>今日入群：</label> 0</span> <span><label>今日退群：</label> 0</span></div>-->
                  <!--                <span class="clickable mark-tag-btn">打标签</span>-->
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>

      <!--  多选操作  -->
      <div v-if="checkMode">
        <div class="select-all" :style="{'bottom':height+'px'}">
          <van-checkbox v-model="selectAll" icon-size="15px">全选</van-checkbox>
          <span class="length">已选择{{ resultCustomer.length }}个客户</span>
          <van-button @click="$refs.customerLabel.$show(tagsValue)" style="height: 31px;" type="info">打标签</van-button>
        </div>
      </div>
      <!--  添加标签  -->
      <addTags ref="tags"/>
      <van-tabbar v-model="active" :safe-area-inset-bottom="true" class="tab">
        <van-tabbar-item replace to="/">
          <span>首页</span>
          <template #icon="props">
            <img v-if="props.active" src="../../assets/images/home.png"/>
            <img v-else src="../../assets/images/home-active.png">
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/contact/index" icon="search">
          <span>客户</span>
          <template #icon="props">
            <img v-if="props.active" src="../../assets/images/customer-active.png"/>
            <img v-else src="../../assets/images/customer.png">
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/tool/index" icon="setting-o">
          <span>运营工具</span>
          <template #icon="props">
            <img v-if="props.active" src="../../assets/images/function-active.png"/>
            <img v-else src="../../assets/images/function.png">
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/my" icon="setting-o">
          <span>我的</span>
          <template #icon="props">
            <img v-if="props.active" src="../../assets/images/my-active.png"/>
            <img v-else src="../../assets/images/my.png">
          </template>
        </van-tabbar-item>
      </van-tabbar>
      <contactAdd ref="contactAdd" @change="search"></contactAdd>
      <customerLabel ref="customerLabel" @change="userTagsChose"/>
      <van-popup v-model="showMorePhone" position="bottom" :style="{ height: 'auto' }">
        <div class="many-phone" v-for="items in phoneNumbers">
          <h4>{{ items }}</h4>
          <a :href="'tel:'+items">
            <van-button type="info" round size="small" style="width: 100px;" @click="modifyUserBtn">拨打电话</van-button>
          </a>
        </div>
      </van-popup>

      <van-popup v-model="showFriend" position="bottom" :style="{ height: 'auto' }">
        <div class="many-phone" v-for="items in phoneNumbers">
          <h4>{{ items }}</h4>
          <van-button type="info" round size="small" style="width: 100px;" @click="navigateToAddCustomers(items)">添加
          </van-button>
        </div>
        <div class="many-phone">
          <h4>绑定已有企微好友</h4>
          <van-button type="info" round size="small" style="width: 100px;" @click="bindFriend">绑定</van-button>
        </div>
        <!--  好友选择-->
        <contact-select ref="contactSelect"/>
      </van-popup>

      <van-popup v-model="showMoreWechat" position="bottom" :style="{ height: 'auto' }">
        <div class="many-phone" v-for="items in wechats">
          <div style="display: flex;align-items: center">
            <img :src="items.avatar" style="width: 50px;height: 50px;margin-right: 10px;border-radius: 50%">
            <h4>{{ items.name }}</h4>
          </div>
          <van-button type="info" round size="small" style="width: 100px;"
                      @click="openEnterpriseChat(items.wxExternalUserid)">去聊天
          </van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import addTags from "./components/addTags";
import {batchCreateContactTag, contactIndex, customerList, workRoom} from '../../api/customer'
import customerLabel from "../channelCode/components/customerLabel";
import contactAdd from "./components/contactAdd";
import ContactSelect from "@/views/index/components/contactSelect";
import Clipboard from 'clipboard'
import {navigateToAddCustomer, setClipboardData, openEnterpriseChat} from "@/utils/wxCodeAuth";
import {ContactApi} from "@/api/contact";
import {Toast} from "vant";

export default {
  components: {
    addTags,
    customerLabel,
    contactAdd,
    ContactSelect
  },
  data() {
    return {
      // 文字
      message: '',
      height: 50,
      // tbas切换
      tabsChose: 0,
      actives: 0,
      active: 1,
      // 搜索条件
      serchVale: '',
      // 客户
      resultCustomer: [],
      checkMode: false,
      // 全选
      selectAll: "",
      // 列表加载
      loading: false,
      finished: false,
      page: 1,
      userList: [],
      pages: {
        perPage: '',
        total: '',
        totalPage: ''
      },
      tagsValue: [],
      dataLiS: {
        page: '',
        perPage: "",
      },
      // 客户群
      baseList: [],
      sreenw: true,
      phoneNumbers: [],//多个手机号
      selectCustomerId: '',
      wechats: [],
      showMorePhone: false,//多个手机号弹框
      showFriend: false,//添加好友弹框
      showMoreWechat: false,//去聊天弹框
      showReset: false
    }
  },
  watch: {
    selectAll(e) {
      if (e) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    actives(e) {
      if (this.actives == 1) {
        this.sreenw = false
        this.serchVale = ''
      } else {
        this.sreenw = true
      }
    },
    serchVale() {
      if (!this.serchVale) {
        this.clear()
      }
    }
  },
  created() {
    this.customerBase()
    if (this.$route.query.content) {
      this.dataLiS = JSON.parse(this.$route.query.content)
      this.showReset = true;
    }
  },
  mounted() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    console.log(isiOS)
    if (isiOS) {
      this.height= 80
    }
  },
  methods: {
    //重置
    resetFilter() {
      this.page = 1
      this.showReset = false;
      this.dataLiS = {
        page: '',
        perPage: "",
      }
      this.$router.push({content: {}});
      delete this.$route.query.content
      localStorage.removeItem('tagsList')
      localStorage.removeItem('topicsList')
      this.serchVale = '';
      this.onLoad();
    },
    //去详情
    routerPush(item) {
      this.$router.push(item)
    },
    //去查询页面
    routerPusht() {
      this.$router.push('/contact/screen?content=' + JSON.stringify(this.dataLiS))
    },
    //批量打标签
    userTagsChose(data) {
      const tagIds = JSON.stringify(data.map(i => {
        return i.id
      }))
      if (this.resultCustomer.length == 0) {
        Toast("需要选择客户")
        return;
      }
      console.log(this.resultCustomer)
      var customerId = []
      this.userList.forEach((item, index) => {
        if (this.resultCustomer.indexOf(index) > -1) {
          customerId.push(item);
        }
      })
      const contactIds = customerId.map(i => i.id)
      const datas = {
        tagIds,
        customerId: contactIds
        // customerId: this.contactId,
        // tagIds
      }
      ContactApi.createContactTag(datas).then(res => {
        this.$message.success('操作成功')
        this.$refs.checkboxGroup.toggleAll();
        this.selectAll = false
        this.resultCustomer = []
        this.checkMode = false
      })
    },
    // 打开多微信号popup
    openManyWechatPopup(item) {
      if (item.wechats.length > 1) {
        this.wechats = item.wechats;
        this.showMoreWechat = true;
      } else {
        this.openEnterpriseChat(item.wechats[0].wxExternalUserid);
      }
    },
    //去聊天界面（调用企微接口）
    async openEnterpriseChat(wxUserId) {
      await openEnterpriseChat('', wxUserId, '')
    },
    // 打开多手机号popup
    openManyPopup(item, type) {
      if (type == 1) {
        this.showMorePhone = true;
      } else {
        this.showFriend = true;
      }
      this.phoneNumbers = item.phoneNumbers;
      this.selectCustomerId = item.id
    },
    //去添加企微好友页面（调用企微接口）
    async navigateToAddCustomers(items) {
      Toast('复制成功')
      await setClipboardData(items)
      await navigateToAddCustomer()
    },
    bindFriend() {
      this.$refs.contactSelect.show(this.selectCustomerId)
    },
    // 客户数据列表加载
    onLoad() {
      this.dataLiS.page = this.page
      this.dataLiS.perPage = 10
      this.dataLiS.keyword = this.serchVale
      customerList(this.dataLiS).then(res => {
        if (this.page == 1) {
          this.userList = res.data.data
        } else {
          this.userList.push(...res.data.data)
        }
        this.pages.total = res.data.total
        if (res.data.data.length < 10) {
          this.finished = true
          this.loading = true
        } else {
          this.page++
          this.finished = false
          this.loading = false
        }
      })
    },
    //客户群列表
    customerBase(e) {
      workRoom({name: e}).then(res => {
        this.baseList = res.data.list
      })
    },
    //搜索
    search() {
      if (this.actives == 0) {
        this.page = 1
        this.onLoad()
      } else {
        this.customerBase(this.serchVale)
      }

    },
    clear() {
      if (this.actives == 0) {
        this.serchT = false
      } else {
        this.customerBase()
      }
    },
    copy(i) {
      var clipboard = new Clipboard('.coopy')
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        //  释放内存
        clipboard.destroy()
      })
    },
    userDetails(e) {
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabbar {
  box-sizing: content-box !important;
  height: 50px !important;
}

/deep/ .select .van-nav-bar__title {
  color: #fff !important;
}

/deep/ .select .van-field__body {
  color: #fff;
}

/deep/ .select .van-search {
  background: #4376ca;
  width: 100%;
}

/deep/ .select .van-search__content {
  background: rgba(255, 255, 255, .3);
  color: #fff;
}

/deep/ .select .van-icon-search {
  color: #fff
}

.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;
  padding-bottom: 25%;

  p {
    margin: 0 !important;
  }

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .select {
    display: flex;
    align-items: center;
    background: #4376ca;
    padding: 12px 16px;
    box-sizing: border-box;
    color: #fff;
  }

  .bottom {
    .bottom-list {
      //background: #fff;
      //padding: 0 5px;
      //font-size: 12px;
      //max-height: 30px;
      //overflow: hidden;
      //padding-bottom: 10px;
      background: #fff;
      padding: 10px 12px;
      font-size: 12px;
      display: flex;
      align-items: baseline;

      //div {
      //  background: #f7f7f7;
      //  border: 1px solid #e9e9e9;
      //  display: inline-block;
      //  padding: 2px 3px;
      //  border-radius: 6px;
      //  margin: 0px 5px;
      //  margin-bottom: 7px;
      //}
    }

    .button {
      padding: 0px 12px;
      background: #fff;
      padding-bottom: 5px !important;

      div {
        font-size: 13px;
        color: #8db1db;
      }
    }

    .unset {
      max-height: unset;
    }

    border-radius: 0 0 7px 7px;
    overflow: hidden;
  }

  .customer {
    img {
      width: 22px;
      height: 22px;
    }

    padding: 0px 12px;
    padding-bottom: 51px;
  }

  .list {
    .choseItem {
      border: 1px solid #1890ff;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 10px 12px;
      border-radius: 7px 7px 0 0;

      .mindel {
        flex: 1;
        margin-left: 10px;
      }

      .avater {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }

      .item {
        //margin-left: -87px;
        .name {
          font-size: 14px;
          color: #000000;
          font-weight: 500;
          max-width: 80px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .line2 {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -moz-box;
          -moz-line-clamp: 2;
          -moz-box-orient: vertical;
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
        }

        div:nth-child(1) {
          h4 {
            font-weight: bold;
            max-width: 168px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span {
            color: #5ec75d;
            margin-left: 5px;
          }
        }

        div:nth-child(2) {
          font-size: 12px;
          color: #6a6a6a;

          div:nth-child(1) {
            //display: flex;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
        }
      }

      .news {
        background: #e8f0ff;
        border-radius: 50%;
        width: 26px;
        height: 26px;
      }


    }
  }

  .groups {

    padding: 0 16px 25px;

    .group {
      margin-top: 10px;
      background: #f9f9f9;
      padding: 12.5px 12.5px 6.3px 14.584px;

      .info {
        padding-bottom: 12.5px;
        border-bottom: 1.03px solid #f2f2f2;

        .col-1 {
          min-width: 0;
        }
      }

      .flex-row.justify-start {
        -webkit-box-align: start;
        align-items: flex-start;
      }

      .flex-row {
        -webkit-box-pack: start;
        -webkit-box-align: center;
        align-items: center;
        display: -webkit-box;
        display: flex;
        justify-content: flex-start;
        padding: 0;

        .col-1 {
          -webkit-box-flex: 1;
          flex: 1;

          .flex-row {
            -webkit-box-pack: start;
            -webkit-box-align: center;
            align-items: center;
            display: -webkit-box;
            display: flex;
            justify-content: flex-start;
            padding: 0;

            .flex-col {
              padding-left: 8.334px;
              -webkit-box-flex: 1;
              flex: 1;
              min-width: 0;
              padding-right: 19.793px;

              .flex-row {
                max-width: 100%;

                .bold {
                  font-size: 13.55px;
                  font-weight: 600;
                  color: #333;
                  //line-height: 1.282125rem;
                  min-width: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  word-break: break-all;
                  -webkit-box-flex: 1;
                  flex: 1;
                }

                span {
                  font-size: 13.55px;
                  color: rgba(0, 0, 0, .45);

                }
              }

              .group-owner-text {
                font-size: 12.5px;
                color: rgba(0, 0, 0, .3);

                .qw-open-data {
                  word-break: break-all;
                }
              }
            }

            .align-left {
              -webkit-box-align: start;
              align-items: flex-start;
            }

            img {
              width: 38px;
              height: 38px;
            }
          }
        }
      }

      .data {
        margin-top: 5.209px;

        .col-1 {
          span {
            color: rgba(0, 0, 0, .65);
            font-size: 12.5px;

            label {
              color: rgba(0, 0, 0, .45);
            }
          }

          span:last-child {
            margin-left: 17.709px;
          }
        }

        .mark-tag-btn {
          background: #1890ff;
          border-radius: 4px;
          font-size: 12.5px;
          color: #fff;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.042px 8.334px;
          display: inline-block;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .select-all {
    position: fixed;
    //position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 10px;
    justify-content: space-between;
    bottom: 50px;

    .length {
      color: rgba(0, 0, 0, .45);
      margin-left: -90px;
    }
  }
}

.many-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
</style>
