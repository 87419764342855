<template>
  <div>
    <Popup
      v-model:show="followAdd"
      position="bottom"
      closeable
      round
      :close-on-click-overlay="false"
      style="height: 75%"
      @click="cancelInput">
      <div style="width: 100%;height: 100%;position: relative;">
        <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">添加跟进</div>
        <Divider style="margin: 20px 0 5px 0;background: #FCFCFC;" />
        <div class="newly_row" v-if="activeTab==2">
          <div style="color: #999;">
            跟进记录：
            <span style="color: #1890FF;cursor: pointer;" @click.stop="newlyBtn">+ 新建</span>
            <input
              type="text"
              class="newly_input"
              placeholder="输入后回车"
              v-if="showAddInput"
              @click.stop
              @keyup.enter="addTemplate"
              v-model="templateName"
            >
          </div>
          <div>
            <div style="color: #999;margin-top: 15px;display: flex;">
              选择事件：
              <!--              下拉框-->
              <div style="margin-top: -5px;">
                <dropDownCase
                  ref="dropDownCase"
                  :optionData="templateData"
                  @change="tabSelect"/>
              </div>
              <span style="margin-left: 10px;cursor: pointer;" @click="deleteTemplate" v-if="selectTemplate!=-1">删除</span>
              <span style="margin-left: 10px;color:#2194FF;cursor: pointer;" v-if="selectTemplate!=-1" @click="selectCont">编辑</span>
            </div>
            <div style="color: #999;margin-top: 15px;" v-if="showModify">
              事件名称：
              <input type="text" class="input_style" v-model="modifyData.name" style="width: 150px;margin-left: -5px;" />
              <span style="margin-left: 10px;color:#2194FF;cursor: pointer;" @click="modifyTemplate">确定</span>
              <span style="margin-left: 10px;cursor: pointer;" @click="showModify=false">取消</span>
            </div>
            <div style="color: #999;">备注：</div>
          </div>
        </div>
        <h3 style="padding: 10px 20px">本次跟进信息：</h3>
        <div class="add_follow_page">
          <div class="add_follow_flex" style="margin-bottom: 15px">
            <div>客户姓名：</div>
            <div class="val-box">
              {{name}}
            </div>
          </div>
          <div class="add_follow_flex" style="margin-bottom: 15px">
            <div>跟进主题：</div>
            <div class="val-box" @click="showPicker = true">
              <div style="font-size: 14px" :style="{'font-weight':topicText?'700':'400'}">{{topicText?topicText:'点击选择主题'}}</div>
              <van-icon name="arrow" size="16"/>
            </div>
          </div>
          <div class="add_follow_flex" style="margin-bottom: 15px">
            <div>跟进状态：</div>
            <div class="val-box" @click="showStatusPicker = true">
              <div style="font-size: 14px" :style="{'font-weight':statusText?'700':'400'}">{{statusText?statusText:'点击选择状态'}}</div>
              <van-icon name="arrow" size="16"/>
            </div>
          </div>
          <div class="add_follow_flex" style="margin-bottom: 15px">
            <div>跟进内容：</div>
            <div class="text-box" style="width: 75%">
            <textarea
                class="textarea"
                placeholder="请输入要添加的跟进信息吧，添加成功后将出现在客户动态列表里~"
                v-model="content"
            ></textarea>
            </div>
          </div>
        </div>
        <div style="padding: 10px 20px;display:flex;align-items: center;justify-content: space-between">
           <div>下次跟进信息：</div>
           <van-switch v-model="createTodo" :active-value="1" :inactive-value="0"/>
        </div>
        <div class="add_follow_page" v-if="createTodo == 1">
          <div class="add_follow_flex" style="margin-bottom: 15px" @click="$refs.time.$show()">
            <div>提醒时间：</div>
            <div class="val-box">
              <div style="font-size: 14px" :style="{'font-weight':todoTime?'700':'400'}">{{todoTime?todoTime:'点击选择时间'}}</div>
              <van-icon name="arrow" size="16"/>
            </div>
          </div>
          <div class="add_follow_flex" style="margin-bottom: 15px">
            <div>内容提醒：</div>
            <div class="text-box" style="width: 75%">
            <textarea
                class="textarea"
                placeholder="请输入要添加的跟进信息吧，添加成功后将出现在客户动态列表里~"
                v-model="todoContent"
            ></textarea>
            </div>
          </div>
        </div>
        <Divider style="margin:15px 0 0 0;background: #FCFCFC;" />
        <div class="foot_row">
          <van-button type="info" block @click="addProcessEvent">保存</van-button>
        </div>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            value-key="title"
            @cancel="showPicker = false"
            @confirm="changeTopic"
        />
      </van-popup>
      <van-popup v-model="showStatusPicker" round position="bottom">
        <van-picker
            show-toolbar
            :columns="statusList"
            value-key="title"
            @cancel="showStatusPicker = false"
            @confirm="changeStatus"
        />
      </van-popup>
    </Popup>
    <addTime ref="time" @change="time"></addTime>
  </div>
</template>
<script>
import { Divider, Popup, Toast } from 'vant'
import dropDownCase from '@/components/dropDownCase/dropDownCase'
// eslint-disable-next-line no-unused-vars
import { ContactFollowApi } from '@/api/contactFollow'
import {topicall} from "@/api/customer";
import addTime from "@/views/index/components/addTime";
export default {
  components: { Popup, Divider, Toast, dropDownCase,addTime },
  data () {
    return {
      followAdd: false,
      activeTab: 1,
      selectTemplate: -1,
      // addScoreBtn: false,
      showAddInput: false,
      templateName: '',
      // 修改数据
      modifyData: {
        id: '',
        name: ''
      },
      contactId:'',
      name:'',
      // 显示修改
      showModify: false,
      // 备注内容
      topicText:'',
      topicId:'',
      statusText:'',
      process:'',
      content: '',
      createTodo:0,
      todoTime:'',
      todoContent:'',

      templateData: [],
      clientHeight: 520,
      //新增属性
      showPicker:false,
      showStatusPicker:false,
      columns:[],
      statusList:[{id:1,title:'新客户'},{id:2,title:'初步沟通'},{id:3,title:'意向客户'},{id:4,title:'付款客户'},{id:5,title:'无意向客户'}]
    }
  },
  methods: {
    //改变主题
    changeTopic(value, index){
      console.log(value, index)
      this.topicText = value.title;
      this.topicId = value.id;
      this.showPicker =false;
    },
    //改变状态
    changeStatus(value, index){
      this.statusText = value.title;
      this.process = value.id;
      this.showStatusPicker =false;
    },
    // 选择时间
    time(e) {
      this.todoTime = e.date +" "+ e.time
    },
    // 更改界面
    changePage () {
      if (this.activeTab == 1) {
        this.clientHeight = 520
      } else {
        this.clientHeight = 415
      }
    },
    // 获取跟进事件列表
    getEventList () {
      ContactFollowApi.templateIndex().then((res) => {
        this.templateData = res.data
        this.$forceUpdate()
        this.$nextTick(() => {
          this.$refs.dropDownCase.emptyOption()
        })
      })
    },
    // 切换选项
    tabSelect (e) {
      this.selectTemplate = e
      this.showModify = false
    },
    // 删除模板
    deleteTemplate () {
      ContactFollowApi.templateDelete({
        id: this.selectTemplate
      }).then((res) => {
        Toast('删除成功')
        this.getEventList()
        this.selectTemplate = -1
      })
    },
    addProcessEvent () {
    //  selectTemplate
      if (this.activeTab == 2) {
        if (this.selectTemplate == -1) {
          Toast('请选择事件')
          return false
        }
      }
      if (!this.topicId) {
        Toast('主题不能为空')
        return false
      }
      if (!this.process) {
        Toast('客户状态不能为空')
        return false
      }
      if (this.content == '') {
        Toast('跟进内容不能为空')
        return false
      }
      if(this.createTodo == 1){
        if (!this.todoTime) {
          Toast('时间不能为空')
          return false
        }
        if (!this.todoContent) {
          Toast('下次联系内容不能为空')
          return false
        }
      }
      ContactFollowApi.store({
        customerId: this.contactId,
        content: this.content,
        topicId: this.topicId,
        process: this.process,
        createTodo: this.createTodo,
        todoTime: this.todoTime,
        todoContent: this.todoContent,
        // followTemplateId: this.selectTemplate
      }).then((res) => {
        Toast('添加成功')
        this.removeData()
        // this.selectTemplate = -1
        this.followAdd = false
        this.$emit('change', true)
      })
    },
    removeData(){
      this.content = ''
      this.topicText = ''
      this.topicId = ''
      this.statusText = ''
      this.process = ''
      this.createTodo = 0
      this.todoTime = ''
      this.todoContent = ''
    },
    topicall(){
      topicall().then(res =>{
        this.columns = res.data
      })
    },
    show (contactId,name) {
      this.followAdd = true
      this.contactId = contactId
      this.name = name
      // this.getEventList()
      this.topicall()
    },
    // 选择内容
    selectCont () {
      this.templateData.forEach((item, index) => {
        if (item.id == this.selectTemplate) {
          this.modifyData = JSON.parse(JSON.stringify(item))
          this.showModify = true
        }
      })
    },
    // 修改模板
    modifyTemplate () {
      if (this.modifyData.name == '') {
        Toast('事件名称不能为空')
        return false
      }
      ContactFollowApi.templateUpdate({
        id: this.modifyData.id,
        name: this.modifyData.name,
        contactId: this.contactId
      }).then((res) => {
        Toast('修改成功')
        this.showModify = false
        this.selectTemplate = -1
        this.getEventList()
      })
    },
    // 添加模板
    addTemplate () {
      if (this.templateName == '') {
        Toast('事件名称不能为空')
        return false
      }
      ContactFollowApi.templateStore({
        name: this.templateName,
        contactId: this.contactId
      }).then((res) => {
        Toast('添加成功')
        this.getEventList()
        this.templateName = ''
        this.showAddInput = false
      })
    },
    newlyBtn () {
      this.showAddInput = true
    },
    cancelInput () {
      this.showAddInput = false
    }
  }
}
</script>
<style scoped lang="less">
.add-follow /deep/ .van-tab{
  flex: none;
  margin-left: 40px;
}
.add-follow /deep/ .van-tabs__wrap{
  height: 50px;
}
.text-box{
  display: flex;
  justify-content:center;
  //background: #FAFAFA;
  border: 1px solid #eee;
}

.val-box{
  display: flex;
  justify-content:center;
  align-items: center;
  //background: #FAFAFA;
  //border: 1px solid #eee;
  //border-radius: 15px;
  //padding: 0 10px;
  //height: 30px;
}

.textarea{
  background: none;
  outline: none;
  border: none;
  height: 100px;
  width: 100%;
  padding: 10px 15px 10px 15px;
  font-size: 13px;
  resize:none;
  color: #B2B2B2;
}
.add_follow_flex{
  display: flex;
  align-items: baseline;
  justify-content:space-between;
}
.add_follow_page{
  font-size: 13px;
  padding: 0 35px;
}
.newly_row{
  margin-top: 20px;
  font-size: 13px;
  padding: 0 25px 0 25px;
}
.foot_row{
  width: calc(100% - 80px);
  //position: absolute;
  //bottom: 25px;
  margin:  0 auto;
  left: 40px;
}
.add_input{
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #D9D9D9;
  height: 40px;
}
.newly_input{
  border: 1px solid #D9D9D9;
  margin-left: 15px;
  padding-left: 10px;
  height: 25px;
}
.input_style{
  padding-left: 10px;
  height: 50px;
  border: 1px solid #D9D9D9;
}
.option_row{
  width: 270px;
  height: 50px;
  border: 1px solid #cccccc;
  position: relative;
}
.option_row select{
  position: absolute;
  top: 0;
  /*清除select的边框样式*/
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 50px;
}
.option_row option{
  height: 10px;
}
</style>
