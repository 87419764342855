<template>
  <div>
    <Popup
      v-model:show="userDetail"
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '600px' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">客户资料详情</div>
      <Divider />
      <div class="user_flex user_page" style="padding-bottom: 20px;">
        <div style="display: flex;">
          <img :src="avatar" alt="" style="width: 50px;height: 50px;">
          <div style="margin-left: 10px;font-size: 14px">
            <div style="font-size: 16px;font-weight: bold;">{{ userName }}</div>
<!--            <div style="color: #72C871;margin-top: 5px;">@微信</div>-->
          </div>
        </div>
      </div>
      <van-cell-group class="data-group">
        <div>
          <van-field
              label="姓名:"
              placeholder="请输入姓名"
              v-model="form.name" />
          <div style="display: flex;align-items: center" v-for="(item,index) in form.phoneNumbers">
            <van-field
                label="电话:"
                style="width: 70%"
                placeholder="请输入电话"
                v-model="item.phoneNumber" />
            <van-button v-if="form.phoneNumbers.length > 1" type="danger" @click="splicePhone(index)"  size="small">删除</van-button>
            <van-button v-if="index+1 == form.phoneNumbers.length" @click="addPhone" style="margin-left: 10px" type="primary" size="small">添加</van-button>
          </div>
          <van-field
              label="邮箱:"
              :placeholder="`请输入邮箱`"
              v-model="form.email" />
          <van-field
              label="微信:"
              :placeholder="`请输入微信`"
              v-model="form.wechat" />
          <van-field
              label="QQ:"
              :placeholder="`请输入QQ`"
              v-model="form.qq" />
          <van-field
              label="地址:"
              :placeholder="`请输入地址`"
              v-model="form.address" />
        </div>
      </van-cell-group>
      <div style="padding:0 20px 15px 20px;">
        <van-button type="info" block @click="modifyUserBtn">修改</van-button>
      </div>
    </Popup>
    <selectTime ref="selectTime" />
    <!--    选择弹窗-->
    <setDetailOption ref="setDetailOption" @change="receiveOption" />
    <!-- 客户合并弹窗  -->
    <contactMerge ref="contactMerge"></contactMerge>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ContactApi } from '@/api/contact'
import { Popup, Divider, DatetimePicker, Toast } from 'vant'
import selectTime from '../../room/components/selectTime'
import setDetailOption from './setDetailOption'
import contactMerge from "@/views/contact/components/contactMerge";
export default {
  components: {
    Popup, Divider, DatetimePicker, selectTime, setDetailOption,contactMerge
  },
  data () {
    return {
      userDetail: false,
      userName: '',
      avatar: '',
      form:{
        id: '',
        name:'',
        phoneNumbers:[],
        email:'',
        wechat:'',
        qq:'',
        address:'',
      },
      checkRowIndex: ''
    }
  },
  methods: {
    receiveOption (e) {
      this.detail[this.checkRowIndex].value = e
    },
    // 弹窗选项
    setOptionCont (item, index) {
      this.checkRowIndex = index
      this.$refs.setDetailOption.show(item)
    },
    addPhone(){
      this.form.phoneNumbers.push({
        id:0,
        phoneNumber:''
      })
    },
    splicePhone(index){
      this.form.phoneNumbers.splice(index,1)
    },
    modifyUserBtn () {
      if(this.form.phoneNumbers.filter(item => !item.phoneNumber).length > 0){
        Toast("手机号不能为空")
        return;
      }
      const param = {...this.form};
      param.phoneNumbers = JSON.stringify(param.phoneNumbers)
      ContactApi.updateContactPortrait(param).then(res => {
        console.log(res.data)
        if(res.data.status == 0){
          this.$refs.contactMerge.show(res.data.conflictUsers)
          this.userDetail = false
        }else {
          Toast('修改成功')
          this.userDetail = false
          this.$emit('change', true)
        }
      })
    },
    show (contactDetailInfo) {
      this.userDetail = true
      if(contactDetailInfo.phoneNumbers.length == 0){
        contactDetailInfo.phoneNumbers.push({
          id:0,
          phoneNumer:''
        })
      }
      this.form = contactDetailInfo
      this.userName = contactDetailInfo.name
      this.avatar = contactDetailInfo.avatar
    },
  }
}
</script>
<style lang="less" scoped>
.user_page{
  padding: 0 35px 0 35px;
  color: #333;
}
.user_flex{
  display: flex;
  justify-content:space-between;
}
.data-group {
  padding: 10px 0 30px 0
}
.picture-wrapper,.check-wrapper {
  align-items: center;
  display: flex;
  padding: 0 16px;
  .title {
    max-width: 400px;
    min-width: 200px;
    font-weight: 400;
    color: #919191;
  }
  .check-text {
    font-weight: 400;
    line-height: 50px;
  }
  .picture {
    width: 128px;
    height: 128px;
    margin-left: 20px;
  }
  .check-group {
    margin-left: -100px;
    .check-item {
      margin-left: 10px;
    }
  }
  .check-group-row{
  }
  .dropdown-menu {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: -20px;
    height: 80px;
  }
}
.upload {
  color: #1989fa;
}
.row_option{
  margin-left: -100px;
  width: 270px;
  height: 35px;
  border: 1px solid #cccccc;
  //line-height: 55px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.option-tip{
  color: #919191;
}
.option-value{

}
</style>
