import request from '@/utils/request'

/**
 * 客户
 */
export class ContactEmployeeTagApi {
  // 个人标签添加
  // static store (params) {
  //   return request({
  //     url: '/contactEmployeeTag/store',
  //     method: 'post',
  //     data: params
  //   })
  // }

  static store (params) {
    return request({
      url: '/crm/tag/storeEmployeeTag',
      method: 'put',
      data: params
    })
  }

  // 客户画像-个人标签-列表
  // static index (params) {
  //   return request({
  //     url: '/contactEmployeeTag/index',
  //     method: 'get',
  //     params
  //   })
  // }
  //客户画像-个人标签-列表-新
  static index (params) {
    return request({
      url: '/crm/tag/employeeTagList',
      method: 'get',
      params
    })
  }

  // 客户画像-个人标签-删除
  // static destroy (params) {
  //   return request({
  //     url: '/contactEmployeeTag/destroy',
  //     method: 'delete',
  //     data: params
  //   })
  // }

  // 客户画像-个人标签-删除-新
  static destroy(params) {
    return request({
      url: '/crm/tag/deleteEmployeeTag',
      method: 'delete',
      data: params
    })
  }


  // 客户画像-个人标签-客户打标签
  // static employeeTagStore (params) {
  //   return request({
  //     url: '/contactEmployeeTag/employeeTagStore',
  //     method: 'post',
  //     data: params
  //   })
  // }

  // 客户画像-个人标签-客户打标签-新
  static employeeTagStore (params) {
    return request({
      url: '/crm/tag/createEmployeeTag',
      method: 'put',
      data: params
    })
  }
}
