import request from '@/utils/request'

/**
 * 客户轨迹
 */
export class ContactTrackApi {
  // 客户动态
  // static index (params) {
  //   return request({
  //     url: '/contactTrack/index',
  //     method: 'get',
  //     params: params
  //   })
  // }

  // 客户动态-新
  static index (params) {
    return request({
      url: '/crm/customer/track',
      method: 'get',
      params: params
    })
  }

  // 客户行为轨迹
  static behaviorList (params) {
    return request({
      url: '/crm/customer/behaviorList',
      method: 'get',
      params: params
    })
  }
}
