<template>
  <div>
    <Popup
      v-model:show="userDetail"
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: 'auto' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">客户合并</div>
      <div style="padding: 10px 20px">
        <div>
          1.字段合并规则：设置主/副客户，有值的覆盖无值的，
          若都有值，以主客户为准<br>
          2.标签、跟进记录、操作记录、沟通记录、订单等数据叠
          加。
        </div>
        <div class="user_flex" style="margin-top: 20px" v-for="item in conflictUsers">
            <div style="align-items: center;display: flex">
              <img style="width: 50px;height: 50px" :src="item.avatar">
              <div style="margin-left: 10px">
                <div style="margin-bottom: 10px">{{item.name}}</div>
                <div>添加时间：{{item.createdAt}}</div>
              </div>
            </div>
            <div class="item" :class="form.customerIdMain == item.id?'active-item':'no-item'" @click="form.customerIdMain = item.id">设为主客户</div>
        </div>
      </div>
      <div style="padding:0 20px 15px 20px;">
        <van-button type="info" block @click="modifyUserBtn">合并以上客户</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ContactApi } from '@/api/contact'
import { Popup, Divider, DatetimePicker, Toast } from 'vant'
export default {
  components: {
    Popup, Divider, DatetimePicker
  },
  data () {
    return {
      userDetail: false,
      checkRowIndex: '',
      conflictUsers:[],
      form:{
        customerIdMain:0,
        customerIdSub:0,
      }
    }
  },
  methods: {
    receiveOption (e) {
      this.detail[this.checkRowIndex].value = e
    },
    // 弹窗选项
    setOptionCont (item, index) {
      this.checkRowIndex = index
      this.$refs.setDetailOption.show(item)
    },
    modifyUserBtn () {
      this.form.customerIdSub = this.conflictUsers.filter(item => item.id != this.form.customerIdMain)[0].id
      ContactApi.customerCombine(this.form).then(res => {
        Toast('合并成功')
        this.userDetail = false
        this.$emit('change', true)
        this.$router.push('/contact/detail?contactId='+this.form.customerIdMain)
      })
    },
    show (conflictUsers) {
      this.conflictUsers = conflictUsers
      this.form.customerIdMain = this.conflictUsers[0].id
      this.userDetail = true
    },
  }
}
</script>
<style lang="less" scoped>
.user_page{
  padding: 0 35px 0 35px;
  color: #333;
}
.user_flex{
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.data-group {
  padding: 10px 0 30px 0
}

.item {
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
}

.no-item {
  border: 1px solid #2B98FF;
  color: #2B98FF;
}

.active-item {
  background: #2B98FF;
  color: #FFFFFF;
}

</style>
