<template>
  <div class="index">
    <van-nav-bar
        title="筛选客户"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
<!--        @click-left="onClickLeft"-->
<!--        @click-right="onClickRight"-->
    <div class="content">
      <div class="item">
<!--        <div class="list-item">-->
<!--          <div class="item-title">-->
<!--            所属客服-->
<!--          </div>-->
<!--          <div @click="$refs.employee.$show(StandbyStaffList,'max')" class="item-content">-->
<!--            {{ StandbyStaffList.length > 0 ? String(StandbyStaff) : '请选择客服' }}-->
<!--            <van-icon name="arrow-down"/>-->
<!--          </div>-->
<!--        </div>-->
        <div>
          <div class="item-title">
            客户类型
          </div>
          <div>
            <div @click="wechatType = ''" :class="{qd: wechatType == ''}"  class="item-content item-lable" style="    padding: 0 26px;">
              不限
            </div>
            <div @click="wechatType = 1" :class="{qd: wechatType == 1}" class="item-content item-lable" style="    padding: 0 26px;">
              企微客户
            </div>
            <div @click="wechatType = 2" :class="{qd: wechatType == 2}"  class="item-content item-lable" style="    padding: 0 26px;">
              非企微客户
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            主题
          </div>
          <div @click="$refs.topic.$show(topicsList)" style="margin-top: 10px;">
            <van-button @click="" style="height: 30px;" plain type="info">选择主题</van-button>
          </div>
          <div v-if="topicsList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin: 0 5px" v-for="(item,index) in topicsList" closeable plain  size="medium" type="primary" @close="topicsList.splice(index,1)">{{ item.title }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            企业标签
          </div>
          <div @click="$refs.tags.$show(tagsList)" style="margin-top: 10px;">
            <van-button @click="" style="height: 30px;" plain type="info">选择标签</van-button>
          </div>
          <div v-if="tagsList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin: 0 5px" v-for="(item,index) in tagsList" closeable plain  size="medium" type="primary" @close="tagsList.splice(index,1)">{{ item.name }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            上次跟进时间
          </div>
          <div class="item-content">
            <div @click="$refs.time.$show(1,1)" style="flex: 1;text-align: center">{{ sTime ? sTime : '开始时间' }}</div>
            -
            <div @click="$refs.time.$show(2,1)" style="flex: 1;text-align: center">{{ endTime ? endTime : '结束时间' }}</div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            下次联系时间
          </div>
          <div class="item-content">
            <div @click="$refs.time.$show(1,2)" style="flex: 1;text-align: center">{{ sTime1 ? sTime1 : '开始时间' }}</div>
            -
            <div @click="$refs.time.$show(2,2)" style="flex: 1;text-align: center">{{ endTime1 ? endTime1 : '结束时间' }}</div>
          </div>
        </div>
        <div style="    justify-content: space-around;
    width: 100%;display: flex;    justify-content: space-between;">
          <van-button @click="onClickLeft" style="width: 35%;" block type="default">取消</van-button>
          <van-button @click="customer" style="width: 35%;" block type="info">确定</van-button>
        </div>
      </div>

    </div>
    <!--  选择客户  -->
    <addCustomer ref="customer"/>
    <!--  选择主题  -->
    <select-topic ref="topic" @change="topicsChose"/>
    <!--  选择标签  -->
    <addTags ref="tags" @change="tagsChose"/>
    <!--  时间选择  -->
    <addTime ref="time" @change="choseTime"/>
    <!--  选择客服  -->
    <employeeIndex ref="employee" @change="userChange"/>
  </div>
</template>

<script>
import addCustomer from "../index/components/addCustomer";
import addTags from "../channelCode/components/customerLabel";
import employeeIndex from "../channelCode/components/employeeIndex";
import addTime from "./components/addTime";
import selectTopic from "./components/selectTopic";

export default {
  components: {
    addCustomer,
    addTags,
    addTime,
    employeeIndex,
    selectTopic
  },
  data() {
    return {
      // 员工
      // StandbyStaffList: [],
      // StandbyStaff:[],
      topicsList: [],
      tagsList: [],
      sTime: '',
      endTime: '',
      sTime1: '',
      endTime1: '',
      //客户类型
      wechatType: '',
      //渠道
    //  qcode: [{chose: true,addWay:2,name: '搜索手机号'},{chose: false,addWay:5,name: '手机通讯录'},{chose: false,addWay:8,name: '安装第三方应用时添加的客服人员'},{chose: false,addWay:202,name: '管理员/负责人分配'},{chose: false,addWay:1003,name: '裂变引流'}],
//      lose: 1
    }
  },
  created(){
    if (this.$route.query.content){
      var dataLiS = JSON.parse(this.$route.query.content)
      if(dataLiS.wechatType){
        this.wechatType = dataLiS.wechatType;
      }
      if(dataLiS.lastFollowTime && dataLiS.lastFollowTime.length > 0){
        this.sTime = dataLiS.lastFollowTime[0];
        this.endTime = dataLiS.lastFollowTime[1];
      }
      if(dataLiS.nextContactTime && dataLiS.nextContactTime.length > 0){
        this.sTime1 = dataLiS.nextContactTime[0];
        this.endTime1 = dataLiS.nextContactTime[1];
      }
      if(localStorage.getItem('tagsList')){
        this.tagsList = JSON.parse(localStorage.getItem('tagsList'));
      }
      if(localStorage.getItem('topicsList')){
        this.topicsList = JSON.parse(localStorage.getItem('topicsList'));
      }
    }
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    // 员工
    userChange(data, info) {
      this.StandbyStaffList = data
      this.StandbyStaff = data.map(i=>i.name)
    },
    topicsChose(data){
      this.topicsList = data
    },
    tagsChose(data){
      this.tagsList = data
    },
    choseTime(data,index,type){
      if(type == 1){
        if(index == 1){
          this.sTime = data
        }else{
          this.endTime = data
        }
      }else {
        if(index == 1){
          this.sTime1 = data
        }else{
          this.endTime1 = data
        }
      }
    },
    activeq(i){
      this.qcode.forEach(i=>{
        return i.chose = false
      })
      this.qcode[i].chose = true
    },
    customer(){
      var tags = '';
      if(this.tagsList.length > 0){
        var obj = {
          type:1
        }
        obj.tagIds = this.tagsList.map(item => item.id)
        tags = JSON.stringify(obj)
        localStorage.setItem('tagsList', JSON.stringify(this.tagsList))
      }else{
        localStorage.removeItem('tagsList')
      }
      if(this.topicsList.length>0){
        localStorage.setItem('topicsList',JSON.stringify(this.topicsList))
      }else{
        localStorage.removeItem('topicsList')
      }
      const content = {
        wechatType: this.wechatType,
        topicId: this.topicsList.length>0?this.topicsList[0].id:'',
        tags: tags,
        lastFollowTime:[this.sTime,this.endTime],
        nextContactTime:[this.sTime1,this.endTime1]
      }
      console.log(content)
      this.$router.push({
        path: "/contact/index",
        query:{
          content: JSON.stringify(content)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }
  .qd{
    color: #1890ff!important;
    border-color: #1890ff!important;
    background-color: #f3f9ff!important;
  }
  .content {
    height: 92%;

    img {
      width: 22px;
      height: 22px;
    }

    padding: 12px 16px;

    .item {
      background: #fff;
      padding: 20px 15px;
      height: 100%;
      overflow: auto;
      padding-bottom: 20px;
      .list-item {
        margin-bottom: 10px;
      }

      .item-title {
        font-weight: bold;
      }

      .item-content {
        width: 100%;
        /* background: red; */
        border: 1px solid #E5E6E7;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        border-radius: 7px;
        margin-top: 10px;
      }

      .item-lable {
        display: inline-block;
        width: auto;
        align-items: center;
        line-height: 36px;
        margin: 10px 3px;
        border-radius: 0;
      }
    }
  }

  .bottom-button {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 20px;
    justify-content: space-around;
    width: 100%;
  }
}
</style>
