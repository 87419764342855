<template>
  <div>
    <van-nav-bar
        title="合同管理"
        left-arrow
        :border="false"
        @click-left="$router.back()"
    />
    <contract-item></contract-item>
  </div>
</template>

<script>
import contractItem from './components/contractItem'
export default {
  name: "index",
  components:{
    contractItem
  }
}
</script>

<style scoped lang="less">
/deep/ .van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__text {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.van-nav-bar__right {
  div {
    color: #fff !important;
  }
}
</style>
