<template>
  <div class="dai-info">
    <img class="top-img" src="@/assets/images/customer/dai_bg.png">
    <div class="dai-item">
      <div class="acea-row row-between row-middle">
        <div class="acea-row row-middle">
          <img style="width: 29px;height: 29px;margin-right: 9px" :src="itemData.avatar">
          <div class="font-size-15-500 font-color-000 line1" style="max-width: 100px">{{ itemData.name }}</div>
        </div>
        <div class="acea-row row-middle">
          <div @click="goDetail(itemData.customerId)"
               class="follow-btn acea-row row-center row-middle font-color-FFF font-size-12-400" style="z-index: 99">
            跟进
          </div>
        </div>
      </div>
      <div class="line" style="margin: 12px 0"></div>
      <div class="acea-row row-middle row-between" style="margin-bottom: 12px">
        <div class="font-size-12-400 font-color-333">
          签约客户：林子豪
        </div>
        <div class="font-size-12-400 font-color-333">
          合同金额：<span style="color: #FF0000">￥9999.99</span>
        </div>
      </div>
      <div class="acea-row row-middle row-between">
        <div class="font-size-12-400 font-color-333">
          创建人：孙尚俊
        </div>
        <div class="font-size-12-400 font-color-333">
          创建时间：2022.11.17 12:31:21
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {updateStatus} from "@/api/homePage";

export default {
  name: "daiItem",
  props: ['item'],
  data() {
    return {
      itemData: {}
    }
  },
  mounted() {
    this.itemData = this.item;
  },
  methods: {
    goDetail(id) {
      this.$router.push("/contact/detail?contactId=" + id)
    }
  }
}
</script>

<style scoped lang="less">
.dai-info {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(22, 139, 246, 0.1);
  border-radius: 6px;
  position: relative;

  .top-img {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 0;
  }

  .dai-item {
    padding: 13px 11px;
    position: relative;
    z-index: 2;

    .follow-btn {
      width: 45px;
      height: 25px;
      background: linear-gradient(90deg, #168BF6, #3DA2FF);
      border-radius: 13px;
    }

    .yu-tag {
      width: 37px;
      height: 18px;
      background: linear-gradient(90deg, #FF5555, #EB3131);
      border-radius: 0px 6px 0px 6px;
    }
  }
}
</style>
