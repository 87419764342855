<template>
  <div class="contract-item">
    <div class="input-area acea-row row-between row-middle">
      <div class="acea-row row-middle">
        <van-icon name="search"  size="22"/>
        <div>
          <van-field style="padding: 0" v-model="form.keyword" placeholder="请输入用户名" />
        </div>
      </div>
      <div class="right-btn acea-row row-center row-middle">
        <van-icon name="search" color="#FFFFFF" size="22"/>
      </div>
    </div>
    <div class="acea-row row-middle row-between" style="margin-bottom: 13px">
      <div class="font-size-12-400 font-color-333">合同总数：<span style="color: #168BF6">0</span></div>
      <div class="acea-row row-middle">
        <van-icon name="add" color="#168BF6" size="16"/>
        <div class="font-size-12-400 font-color-333" style="margin-left: 3px">新增合同</div>
      </div>
    </div>
    <div style="margin-top: 13px">
      <contract-card :item="item" v-for="item in contractList"></contract-card>
    </div>

  </div>
</template>

<script>
import ContractCard from "@/views/contract/components/contractCard";
export default {
  name: "contractItem",
  components:{
    ContractCard
  },
  data(){
    return {
      form:{
        keyword:''
      },
      contractList:[{
        avatar:'',
        name: 'sldfll'
      }],
    }
  }
}
</script>

<style scoped lang="less">
  .contract-item {
    padding: 14px 12px;


    .input-area {
      width: 100%;
      height: auto;
      padding: 3px 3px 3px 11px;
      background: #FFFFFF;
      border: 1px solid #168BF6;
      border-radius: 18px;
      margin-bottom: 18px;

      .right-btn {
        width: 44px;
        height: 30px;
        background: #168BF6;
        border-radius: 15px;
      }
    }
  }
</style>
