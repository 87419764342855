<template>
  <div class="component">
    <van-action-sheet v-model="show" title="选择主题">
      <div class="content" style="color: #818181;">
        <div class="user-list">
          <van-radio-group @change="fullRadio" v-if="user.length > 0" icon-size="15px" v-model="radio">
            <div>
                <div v-for="(item,index) in user" :key="index" :class="{'active-background':  radio == item.id }"
                     class="user" style="padding: 10px 16px;">
                  <div>
                    {{ item.title }}
                  </div>
                  <van-radio ref="choseRadio" shape="square" :name="item.id"></van-radio>
                </div>
            </div>
          </van-radio-group>
          <van-empty
              v-else
              class="custom-image"
              :image="require('../../../assets/images/mobiletodo.png')"
              description="分组"
          />
        </div>
        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px"
                    type="info">确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import { Divider, Step, Steps, Tabbar, TabbarItem, Toast, Popover } from 'vant'
import {topicall} from "@/api/customer";

export default {
  data() {
    return {
      show: false,
      radio: '',
      user: [],
    }
  },
  watch: {},
  methods: {
    $show(data) {
      this.show = true
      // this.radio = data
      if (data.length <= 0 || this.user.length <= 0 && data.length > 0) {
        topicall({
          "mode":'tree'
        }).then(res => {
          res.data.forEach(item => {
            item.show = false
          })
          this.user = res.data;
          if(data.length > 0){
            this.editCode(data)
          }
        })
      }else{
        this.editCode(data)
      }
    },
    determine(e) {
      if (!this.radio) {
        Toast.fail('请选择客户');
        return;
      }
      console.log(this.user,this.radio)
      const data = this.user.filter(item => item.id === this.radio)
      this.$emit("change", data)
      this.show = false
      this.radio = ''
    },
    fullRadio() {
    },
    editCode(data) {
      console.log(data,this.user);
      this.$nextTick(() => {
        data.forEach((item,index)=>{
          this.user.forEach((its, ind) => {
            if (item.id == its.id) {
              this.$refs.choseRadio[ind].toggle(true)
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {
    padding: 0;

    .user-list {
      height: 275px;
      overflow: auto;
      .group_name{
        color: #747474;
        margin: 25px 0 15px 0;
        border-left: 8px solid #1989FA;
        padding-left: 8px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
