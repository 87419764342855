<template>
  <div class="contact-page">
    <div v-show="showContact" @click="closeDown" class="show-cont">
      <div class="show-customer">
        <van-nav-bar
            title="成员详情"
            left-text="返回"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="personal_page" style="background: #2C63CA">
          <!--      用户信息-->
          <div class="page_flex">
            <div style="display: flex;">
              <img :src="infoDetail.avatar" alt="" style="width: 40px;height:40px;">
              <div style="margin-left: 10px;">
                <div style="font-size: 14px;color: #FFFFFF">{{ infoDetail.name }}</div>
                <div v-if="infoDetail.wechats.length > 0" style="display: flex;align-items: center">
                  <div style="color:#55DA72;">@微信好友：</div>
                  <img :src="item.avatar" v-for="item in infoDetail.wechats" alt="" style="width: 20px;height: 20px">
                </div>
              </div>
            </div>
            <span style="font-size: 12px;color: #C9DCFF;margin-top: 15px;cursor: pointer;">
              <span @click="showContactDetail">详情<van-icon name="arrow" /></span>
            </span>
          </div>
        </div>
        <div class="personal-area" @click="showFriendRoom" style="cursor: pointer;margin-top: 10px;">
          <div class="page_flex">
            <div class="flex-align-center">
              <div class="title-line"></div>
              <div class="title">企业标签</div>
            </div>
            <div @click="$refs.corpTag.show(contactId)">
              <van-icon color="#168BF6" name="add" />
              <span>添加标签</span>
            </div>
          </div>
          <div style="margin-top: 10px">
            <div class="flex-align-center" style="flex-wrap: wrap" v-if="infoDetail.newCropTag && infoDetail.newCropTag.length > 0">
              <div class="title-tag title-noactive tag_name" style="margin-bottom: 8px;" v-for="(item,index) in infoDetail.newCropTag">
                {{ item.name }}
                <van-icon
                    name="clear"
                    class="close-icon"
                    @click.stop="delEnterpriseTag(item)" />
              </div>
            </div>
            <div class="flex-align-center"  style="justify-content: center" v-if="(infoDetail.newCropTag && infoDetail.newCropTag.length == 0) || !infoDetail.newCropTag">
              <img style="width: 16px;height: 16px;margin-right: 8px" src="../../assets/images/customer/smile.png"/>
              <div class="font-size-12-400 font-color-999">暂无标签，快去点击右上角添加哦~~</div>
            </div>
          </div>
        </div>
        <div class="personal-area" @click="showFriendRoom" style="cursor: pointer;margin-top: 10px;">
          <div class="page_flex">
            <div class="flex-align-center">
              <div class="title-line"></div>
              <div class="title">个人标签</div>
            </div>
            <div @click="$refs.employeeTag.show(contactId)">
              <van-icon color="#168BF6" name="add" />
              <span>添加标签</span>
            </div>
          </div>
          <div style="margin-top: 10px">
            <div class="flex-align-center"  style="flex-wrap: wrap" v-if="infoDetail.employeeTag && infoDetail.employeeTag.length > 0">
              <div class="title-tag title-noactive tag_name" style="margin-bottom: 0;" v-for="(item,index) in infoDetail.employeeTag">
                <div>{{ item.name }}</div>
                <van-icon
                    name="clear"
                    class="close-icon"
                    @click.stop="delContactTag(item)" />
              </div>
            </div>
            <div class="flex-align-center"  style="justify-content: center" v-if="(infoDetail.employeeTag && infoDetail.employeeTag.length == 0) || !infoDetail.employeeTag">
                <img style="width: 16px;height: 16px;margin-right: 8px" src="../../assets/images/customer/smile.png"/>
                <div class="font-size-12-400 font-color-999">暂无标签，快去点击右上角添加哦~~</div>
            </div>
          </div>
        </div>
        <div class="personal-area" @click="showFriendRoom" style="cursor: pointer;margin-top: 10px;" v-if="infoDetail.topics.length > 0">
          <div class="flex-align-center">
            <div class="title-line"></div>
            <div class="title">参与主题</div>
          </div>
          <div style="overflow: hidden">
            <div class="status-scroll">
              <div class="title-tag" v-for="(item,index) in infoDetail.topics"
                   :class="topicActive == index?'title-active':'title-noactive'" @click="topicActive = index">
                   {{ item.title }}
              </div>
            </div>
          </div>
          <div class="choseItem" style="background: rgb(43, 152, 255, 0.1);">
            <div style="color: #168BF6">{{infoDetail.topics[topicActive].title}} :</div>
            跟进阶段：{{infoDetail.topics[topicActive].process?infoDetail.topics[topicActive].process:''}}<br>
            主题介绍：{{infoDetail.topics[topicActive].description}}
          </div>
        </div>

        <div class="personal-area" @click="showFriendRoom" style="cursor: pointer;margin-top: 10px;">
          <div class="flex-align-center">
             <div class="title-line"></div>
             <div class="title">Ta添加的</div>
          </div>
          <div class="page_flex" style="margin-top: 10px;align-items: center;">
            <div style="display: flex;align-items: center" class="personal-tip">
              <div v-for="(item,index) in infoDetail.employees" :key="index" >
                <img :src="item.avatar" alt="" class="employee-img" v-if="index<3">
              </div>
              共{{ employees.length }}个员工</div>
          </div>
        </div>
        <div style="background: #fff;margin-top: 10px;padding-top: 10px">
          <van-tabs v-model="active" color="#1890ff">
            <van-tab title="客户详情">
              <div style="padding: 0 20px">
                <div>客户资料:</div>
                <div style="padding: 10px 10px;">
                  <div style="margin-top: 10px;">
                    <div style="display: flex;align-items: center">
                      <div class="info_name" style="width: 50px;">姓名：</div>
                      <div style="width: 85%;word-break: break-word">{{infoDetail.name}}</div>
                    </div>
                  </div>
                  <div style="margin-top: 10px;">
                    <div style="display: flex;align-items: center">
                      <div class="info_name" style="width: 50px;">电话：</div>
                      <div style="width: 85%;word-break: break-word" v-if="infoDetail.phoneNumbersText">{{infoDetail.phoneNumbersText}}</div>
                      <div v-else>-</div>
                    </div>
                  </div>
                  <div class="page_flex" style="margin-top: 10px;">
                    <div style="display: flex;">
                      <div class="info_name" style="width: 50px;">微信：</div>
                      <div>{{infoDetail.wechat?infoDetail.wechat:'-'}}</div>
                    </div>
                  </div>
                  <div class="page_flex" style="margin-top: 10px;">
                    <div style="display: flex;">
                      <div class="info_name" style="width: 50px;">QQ：</div>
                      <div>{{infoDetail.qq?infoDetail.qq:'-'}}</div>
                    </div>
                  </div>
                  <div class="page_flex" style="margin-top: 10px;">
                    <div style="display: flex;">
                      <div class="info_name" style="width: 50px;">邮箱：</div>
                      <div>{{infoDetail.email?infoDetail.email:'-'}}</div>
                    </div>
                  </div>
                  <div style="margin-top: 10px;">
                    <div style="display: flex;align-items: center">
                      <div class="info_name" style="width: 50px;">地址：</div>
                      <div style="width: 85%;word-break: break-word">{{infoDetail.address?infoDetail.address:'-'}}</div>
                    </div>
                  </div>
                </div>
                <div>平台资料:</div>
                <div style="padding: 10px 10px;">
                  <div class="page_flex" style="margin-top: 10px;" v-for="item in infoDetail.fields">
                    <div style="width: 50%;"><span class="info_name">{{item.label}}：</span>{{ item.value?item.value:'-' }}</div>
                  </div>
                </div>
              </div>
            </van-tab>
            <van-tab title="动态">
              <div>
                <div class="personal_page" style="display: flex;justify-content:space-between;align-items: center;padding: 0 20px;">
                  <div class="row_name">动态</div>
                  <div class="drop-box" style="font-size: 12px">
                    <dropDownCase :optionData="optionData" @change="selectContactTrack" ref="dropDownCase"/>
                  </div>
                </div>
                <div style="position: relative;">
                  <div class="show-list">
                    <van-list
                        v-model:loading="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoadData">
                      <van-cell v-for="(item,index) in trackList" :key="index">
                        <div style="display: flex;">
                          <div>{{ item.week }}</div>
                          <div style="margin-left: 15px;">{{ item.day }}</div>
                        </div>
                        <Steps
                            direction="vertical"
                            active-icon="clock"
                            inactive-icon="clock">
                          <Step v-for="(obj,idx) in item.list" :key="idx">
                            <div style="position: relative;">
                              <div class="group_time left-time" style="left: -75px;margin-top: 16px;">
                                {{ obj.time }}
                              </div>
                              <div class="trail-icon">
                                <img src="../../assets/clockImg.png" alt="" v-if="obj.event==8">
                                <img src="../../assets/tagsImg.png" alt="" v-else>
                              </div>
                              <div class="group_event" v-if="obj.isNew==0">{{ obj.content }}</div>
                              <div class="group_event">{{ obj.content.title }}</div>
                            </div>
                            <div style="display: flex;justify-content:space-between;">
                              <div class="group_time">
                                <span v-if="obj.todoTime">[{{ obj.todoTime }}]</span>
                                {{ obj.content.content }}
                              </div>
<!--                              <div-->
<!--                                  style="color: #74BCFF;cursor: pointer;width: 35px;"-->
<!--                                  v-if="obj.event==6||obj.event==7"-->
<!--                                  @click="$refs.contactFollowModify.show(obj)"-->
<!--                              >修改-->
<!--                              </div>-->
                            </div>
                            <div class="group_time" v-if="obj.createName">{{ obj.createName }} 设置</div>
                          </Step>
                        </Steps>
                      </van-cell>
                    </van-list>
                  </div>
                </div>
              </div>
            </van-tab>
            <van-tab title="合同">内容 3</van-tab>
            <van-tab title="商机">内容 4</van-tab>
            <van-tab title="订单">内容 4</van-tab>
            <van-tab title="行为轨迹">
              <div>
                <div class="personal_page" style="display: flex;justify-content:space-between;align-items: center;padding: 0 20px;">
                  <div class="row_name">行为轨迹</div>
                </div>
                <div style="position: relative;">
                  <div class="show-list">
                    <van-list
                        v-model:loading="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoadBehaviorListData">
                      <van-cell v-for="(item,index) in behaviorList" :key="index">
                        <div style="display: flex;">
                          <div>{{ item.week }}</div>
                          <div style="margin-left: 15px;">{{ item.day }}</div>
                        </div>
                        <Steps
                            direction="vertical"
                            active-icon="clock"
                            inactive-icon="clock">
                          <Step v-for="(obj,idx) in item.list" :key="idx">
                            <div style="position: relative;">
                              <div class="group_time left-time" style="left: -75px;margin-top: 16px;">
                                {{ obj.time }}
                              </div>
                              <div class="trail-icon">
                                <img src="../../assets/clockImg.png" alt="" v-if="obj.event==8">
                                <img src="../../assets/tagsImg.png" alt="" v-else>
                              </div>
                              <div class="group_event">{{ obj.content.title }}</div>
                            </div>
                            <div style="display: flex;justify-content:space-between;">
                              <div class="group_time">
                                {{ obj.content.content }}
                              </div>
                            </div>
                          </Step>
                        </Steps>
                      </van-cell>
                    </van-list>
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
<!--      <div class="seek-row">-->
<!--        <van-button type="info" block @click="seeContactDetail">查看客户</van-button>-->
<!--      </div>-->
      <Tabbar :z-index="1000" v-model="tabbarActive" :fixed="true" :border="false">
        <TabbarItem name="1" @click="changeBar(1)">
          <div style="font-size: 12px;">打电话</div>
          <template #icon="props">
            <img v-if="tabbarActive == 1" src="../../assets/phonesImg.png" style="width: 17px;height: 17px;margin-top: 5px"/>
            <img v-else src="../../assets/phoneImg.png" style="width: 17px;height: 17px;margin-top: 5px">
          </template>
        </TabbarItem>
        <TabbarItem name="2" @click="changeBar(2)">
          <div style="font-size: 12px;">添加待办</div>
          <template #icon="props">
            <img v-if="tabbarActive == 2" src="../../assets/alarmImg.png" style="width: 17px;height: 17px;margin-top: 5px"/>
            <img v-else src="../../assets/alarmImg1.png" style="width: 17px;height: 17px;margin-top: 5px">
          </template>
        </TabbarItem>
        <TabbarItem name="3" @click="changeBar(3)">
          <div style="font-size: 12px;">添加跟进</div>
          <template #icon="props">
            <img v-if="tabbarActive == 3" src="../../assets/tagsImg.png" style="width: 17px;height: 17px;margin-top: 5px"/>
            <img v-else src="../../assets/tagImg.png" style="width: 17px;height: 17px;margin-top: 5px">
          </template>
        </TabbarItem>
        <TabbarItem name="4" @click="changeBar(4)">
          <div style="font-size: 12px;">更多选项</div>
          <template #icon="props">
            <img v-if="tabbarActive == 4" src="../../assets/more1sImg.png" style="width: 17px;height: 17px;margin-top: 5px"/>
            <img v-else src="../../assets/more1Img.png" style="width: 17px;height: 17px;margin-top: 5px">
          </template>
        </TabbarItem>
      </Tabbar>
    </div>
    <div class="load-box" :style="{'height':clientHeight+'px'}" v-if="!showContact">
      <van-loading type="spinner" color="#1989fa" class="load-img" v-if="!showContact" />
    </div>
    <!--    客户详情-->
    <contactDetail ref="contactDetail" @change="updatePage" />
    <!--    客户评分-->
    <contactScore ref="contactScore" />
    <!--    客户积分-->
    <contactIntegral ref="contactIntegral" />
    <!--    选择跟进状态-->
    <contactFollowStage ref="contactFollowStage" @change="updatePage" />
    <!--    好友和群聊-->
    <friendRoom ref="friendRoom" />
    <!--    添加企业标签-->
    <corpTag ref="corpTag" @change="updatePage" />
    <!--    个人标签-->
    <employeeTag ref="employeeTag" @change="updatePage" />
    <!--    打电话-->
    <van-popup v-model="showMorePhone" position="bottom" :style="{ height: 'auto' }">
      <div class="many-phone" v-for="items in infoDetail.phoneNumbers">
        <h4>{{items.phoneNumber}}</h4>
        <a :href="'tel:'+items.phoneNumber">
          <van-button type="info" round size="small" style="width: 100px;">拨打电话</van-button>
        </a>
      </div>
    </van-popup>
    <!--    添加待办-->
    <contactTodo ref="contactTodo" @change="updatePage" />
    <!--    添加跟进-->
    <contactFollow ref="contactFollow" @change="updatePage" />
    <!--    修改跟进-->
    <contactFollowModify ref="contactFollowModify" @change="updatePage" />
    <!--    更多弹层-->
    <van-action-sheet
        v-model="showMore"
        :actions="actions"
        cancel-text="取消"
        description="更多选项"
        close-on-click-action
        @select="changeItem"
    />
    <!--    转接客户-->
    <contactTransfer ref="contactTransfer" />
    <!--    模板拉群-->
    <roomTemplatePull ref="roomTemplatePull" />
  </div>
</template>
<script>
import { ContactApi } from '@/api/contact'
// eslint-disable-next-line no-unused-vars
import { ContactTrackApi } from '@/api/contactTrack'
import { Divider, Step, Steps, Tabbar, TabbarItem, Toast, Popover } from 'vant'
import dropDownCase from '@/components/dropDownCase/dropDownCase'
import contactDetail from './components/contactDetail'
import contactScore from './components/contactScore'
import contactIntegral from './components/contactIntegral'
import contactFollowStage from './components/contactFollowStage'
import friendRoom from './components/friendRoom'
import corpTag from './components/corpTag'
import employeeTag from './components/employeeTag'
import contactTodo from './components/contactTodo'
import contactFollow from './components/contactFollow'
import contactFollowModify from './components/contactFollowModify'
import contactTransfer from './components/contactTransfer'
import roomTemplatePull from './components/roomTemplatePull'
// eslint-disable-next-line no-unused-vars
import { openUserProfile,getContext,getCurExternalContact } from '@/utils/wxCodeAuth'
export default {
  components: {
    contactDetail,
    roomTemplatePull,
    contactTransfer,
    Divider,
    Step,
    Steps,
    Popover,
    contactScore,
    contactIntegral,
    contactFollowStage,
    friendRoom,
    corpTag,
    employeeTag,
    contactTodo,
    contactFollow,
    Tabbar,
    TabbarItem,
    dropDownCase,
    contactFollowModify
  },
  data () {
    return {
      showMorePhone:false,
      showMore:false,
      actions: [
        { name: '移入公海',subname: '将客户移至公海' },
        { name: '移入废弃' ,subname: '将客户移至废弃'},
        { name: '转接客户',subname: '将客户转移给其他同事'},
      ],
      tabbarActive:'0',
      topicActive:0,
      active:0,
      infoDetail: {
        fieldPivot: {},
        newCropTag:[],
        employeeTag:[],
        roomName: [],
        employeeName: [],
        moment: {},
        phoneNumbers:[],
        showAllCropTag: true,
        showAllEmployeeTag:true,
      },
      infoUpdateDetail: {
      },
      contactId:'',
      wxExternalUserid:'',
      trackList: [],
      employees: [],
      clientEvent: 0,
      showContact: false,
      finished: false,
      loading: false,
      page: 1,
      behaviorfinished: false,
      behaviorloading: false,
      behaviorpage: 1,
      behaviorList:[],
      clientHeight: '',
      optionData: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '客户动态'
        },
        {
          id: 3,
          name: '修改信息'
        },
        {
          id: 6,
          name: '跟进记录'
        },
        {
          id: 8,
          name: '待办事项'
        },
        {
          id: 7,
          name: '模板记录'
        }
      ],
      //  更新时间
      refreshTime: '',
      //  点赞次数
      giveNum: false,
      giveTips: [{
        text: '企业微信朋友圈点赞总次数'
      }],
      //  评论次数
      commentNum: false,
      commentTips: [{
        text: '企业微信朋友圈评论总次数'
      }]
    }
  },
  async created () {
    this.contactId = this.$route.query.contactId
    this.wxExternalUserid = this.$route.query.wxExternalUserid
    this.showContact = true
    this.trackList = []
    this.clientHeight = document.documentElement.clientHeight
    if (this.contactId === 0 || this.contactId === null || this.contactId === undefined) {
      const entry = await getContext()
      if (entry === 'group_chat_tools') {
        this.$toast({ position: 'top', message: '群聊内容' })
        //this.$router.push({ path: '/room', query: { agentId } })
        return
      }
      if (entry !== 'single_chat_tools') {
        this.$toast({ position: 'top', message: '请从单聊会话的工具栏进入' })
        return
      }
      this.wxExternalUserid = await getCurExternalContact()
      console.log('外部联系人id',this.wxExternalUserid)
    }
    // const entry = await getContext()
    // const agentId = this.$route.query.agentId
    // if (entry === 'group_chat_tools') {
    //   this.$router.push({ path: '/room', query: { agentId } })
    //   return
    // }
    // if (entry !== 'single_chat_tools') {
    //   this.$toast({ position: 'top', message: '请从单聊会话的工具栏进入' })
    //   return
    // }
    // await this.getContactInfo()
    // if (!this.contactId) {
    //   this.$toast({ position: 'top', message: '获取客户信息失败，请重新进入应用' })
    //   return
    // }

    // 基本信息
    this.getInfo()
    // 互动轨迹
    // setTimeout(() => {
    //   this.$refs.dropDownCase.setOption(0)
    //   this.onLoadData()
    // }, 1000)
    //  客户详情信息
    // this.getDetail()
  },
  methods: {
    async seeContactDetail () {
      await openUserProfile(2, this.wxExternalUserid)
    },
    onClickLeft () {
      this.$router.back()
    },
    // 获取当前时间
    getCurrentTime () {
      const date = new Date()
      const year = date.getFullYear()
      const month = new Date().getMonth() + 1
      const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
      const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
      const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      this.refreshTime = year + '-' + month + '-' + day + ' ' + hh + ':' + mm
    },
    // 客户画像
    getDetail () {
      ContactApi.getContactPortrait({
        contactId: this.contactId
      }).then(res => {
        this.detail = res.data
      })
    },
    // 修改客户信息
    modifyContact () {
      ContactApi.updateContactPortrait({
        contactId: this.contactId,
        userPortrait: JSON.stringify(this.detail)
      }).then(res => {
        Toast('修改成功')
        this.updatePage(true)
      })
    },
    // 关闭下拉
    closeDown () {
      if(this.$refs.dropDownCase){
        this.$refs.dropDownCase.closeOption()
      }
    },
    // 更新页面
    updatePage (e) {
      if (e) {
        this.getInfo()
        this.trackList = []
        this.page = 1
        this.onLoadData()
      }
    },
    // 处理互动轨迹数据
    handTrackList (data) {
      if (data.length != 0) {
        if(this.page == 1){
          this.trackList = data;
        }else {
          this.trackList = this.trackList.concat(data)
        }
        this.loading = false
        this.page++
      } else {
        this.finished = true
      }
    },
    // 选择客户动态
    selectContactTrack (e) {
      this.clientEvent = e
      this.trackList = []
      this.page = 1
      this.onLoadData()
    },
    // 下拉加载
    onLoadData () {
      this.loading = true
      this.finished = false
      this.getTrackData()
    },
    // 互动轨迹
    getTrackData () {
      ContactTrackApi.index({
        customerId: this.contactId,
        event: this.clientEvent,
        page: this.page
        // perPage: 10
      }).then(res => {
        this.handTrackList(res.data.data)
      }).catch(err => {
        this.loading = false
        this.finished = true
      })
    },
    // 下拉加载
    onLoadBehaviorListData () {
      this.loading = true
      this.finished = false
      this.getBehaviorData()
    },
    // 互动轨迹
    getBehaviorData () {
      ContactTrackApi.behaviorList({
        customerId	: this.contactId,
        page: this.page,
        perPage: 10
      }).then(res => {
        this.behaviorList = this.behaviorList.concat(res.data.data)
        if (res.data.data.length < 10) {
          this.finished = true
        } else {
          this.loading = false
          this.page++
        }
      }).catch(err => {
        this.loading = false
        this.finished = true
      })
    },
    // 删除企业标签
    delEnterpriseTag (obj) {
      ContactApi.deleteContactTagApi({
        customerId: this.contactId,
        tagId: obj.id
      }).then((res) => {
        Toast('删除成功')
        this.updatePage(true)
      })
    },
    // 删除个人标签
    delContactTag (item) {
      ContactApi.employeeTagDeleteApi({
        customerId: this.contactId,
        tagId: item.id
      }).then((res) => {
        Toast('删除成功')
        this.updatePage(true)
      })
    },
    // 显示用户详情
    showContactDetail () {
      console.log(this.infoDetail)
      const contactDetailInfo = {
        id: this.contactId,
        avatar: this.infoDetail.avatar,
        name: this.infoDetail.name,
        phoneNumbers: this.infoDetail.phoneNumbers,
        email: this.infoDetail.email,
        qq: this.infoDetail.qq,
        address: this.infoDetail.address,
        wechat: this.infoDetail.wechat,
      }
      console.log(contactDetailInfo)
      this.$refs.contactDetail.show(contactDetailInfo)
    },
    // 显示好友群聊
    showFriendRoom () {
      // const roomData = {
      //   roomName: this.infoDetail.roomName,
      //   employeeName: this.employees
      // }
      // this.$refs.friendRoom.show(roomData)
    },
    // 客户详情基本信息
    getInfo () {
      // 获取当前时间
      this.getCurrentTime()
      const params = {
        id: this.contactId,
        wxUserId: this.wxExternalUserid
      }
      ContactApi.customerDetail(params).then(res => {
        this.infoDetail = res.data
        this.infoDetail.newCropTag = [];
        if(this.infoDetail.corpTag && this.infoDetail.corpTag.length > 0){
          this.infoDetail.corpTag.forEach(item => {
            item.tags.forEach(items => {
              this.infoDetail.newCropTag.push(items);
            })
          })
        }
        if(this.infoDetail.newCropTag.length > 3){
          this.infoDetail.showAllCropTag = false;
        }
        if(this.infoDetail.employeeTag.length > 3){
          this.infoDetail.showAllEmployeeTag = false;
        }
        this.infoUpdateDetail = res.data
        this.contactId = this.infoDetail.id
        if(this.infoDetail.phoneNumbers.length > 0){
          this.infoDetail.phoneNumbersText = this.infoDetail.phoneNumbers.map(item => item.phoneNumber).join("/")
        }
        this.employees = this.infoDetail.employees
        // this.wxExternalUserid = this.infoDetail.wxExternalUserid
        //setTimeout(() => {
          this.showContact = true
        //}, 500)
      })
    },
    changeBar (type) {
      if(type == 1){
        if(this.infoDetail.phoneNumbers.length == 0){
          Toast('没有手机号');
          return;
        }
        this.showMorePhone = true;
      }
      if (type == 2) {
        this.$refs.contactTodo.show(this.contactId)
      }
      if (type == 3) {
        this.$refs.contactFollow.show(this.contactId,this.infoDetail.name)
      }
      if(type == 4){
        this.showMore = true
      }

      // if (type == 4) {
      //   const userInfo = {
      //     contactId: this.contactId,
      //     avatar: this.infoDetail.avatar,
      //     name: this.infoDetail.name,
      //     wxExternalUserid: this.infoDetail.wxExternalUserid
      //   }
      //   this.$refs.roomTemplatePull.show(userInfo)
      // }
    },
    changeItem(action,index){
      var list = []
      list.push(this.contactId)
      if(index == 0){
        ContactApi.changePoolType({
          poolType:0,
          customerIds: JSON.stringify(list)
        }).then(res => {
          this.$router.push("/contact/index")
        })
      }else if(index == 1){
        ContactApi.changePoolType({
          poolType:2,
          customerIds: JSON.stringify(list)
        }).then(res => {
          this.$router.push("/contact/index")
        })
      }else if (index == 2) {
        this.$refs.contactTransfer.show(this.contactId)
      }
    },
    textFoll(e){
      switch (e){
        case 1:
          return '新客户'
        break;
        case 2:
          return '初步沟通'
          break;
        case 3:
          return '意向客户'
          break;
        case 4:
          return '付款客户'
          break;
        case 5:
          return '无意向客户'
          break;
        default:
          return "暂无状态"
      }
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__text {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.van-nav-bar__right {
  div {
    color: #fff !important;
  }
}
.contact-page{
  font-size: 14px;
}
.show-cont{
}
.show-customer{
  height: calc(100vh - 57px);
  overflow-y: scroll;
}
.seek-row{
  background: #fff;
  padding: 2px 30px;
}
.personal-tip{
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.employee-img{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 10px;
}
.drop-box{
  //position: absolute;
  //top: 10px;
  //right: 15px;
  z-index: 200;
}
.contact-page{
  background: #F3F4F8;
}
.load-box{
  width: 100%;
  position: relative;
}
.load-img{
  position: absolute;
  top: 40%;
  left: 50%;
}
.page_flex{
  display: flex;
  justify-content:space-between;
}
.personal_page{
  font-size: 14px;
  padding: 20px;
  background: #fff;
  color:#666;
}

.personal-area {
  padding: 15px 12px;
  background: #fff;

  .title {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
  }

  .title-line {
    width: 3px;
    height: 16px;
    background: #168BF6;
    border-radius: 2px;
    margin-right: 11px;
  }
}

.info_input{
  border:0;
  background: #fff;
}
.info_name{
  color: #999;
}
.row_name{
  color: #333;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
.add_btn_style{
  height: 40px;
  width: 110px;
  font-size: 14px;
  color: #999;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  box-sizing: content-box;
}
.add_btn_style:hover{
  border: 1px dashed #E8E8E8;
}
.interaction_num{
  span{
    font-size: 14px;
    color: #2791FF;
  }
}
.show-list{
}
.show-list /deep/ .van-steps__items{
  padding-left: 50px;
}
.left-time{
  position: absolute;
  top: -15px;
  left: -125px;
  z-index: 100;
}
//步骤条
.trail-icon{
  position: absolute;
  top: 0;
  left: -30px;
  z-index: 100;
  background: #fff;
  width: 20px;
  height: 20px;
  border: 1px solid #42A4FF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content:center;
  img{
    width: 10px;
    height: 10px;
  }
}
.show-list /deep/ .van-step__icon--active{
  //color: #42A4FF;
  color: #fff;
}
.show-list /deep/ .van-step__circle-container{
  font-size: 14px;
  //color: #42A4FF;
  color: #fff;
}
.show-list /deep/ .van-step__title {
  color: #333;
  margin-left: 5px;
}
.group_event{
  font-weight: bold;
}
.group_time{
  margin-top: 15px;
  color: #747474;
}
.option_row{
  width: 270px;
  height: 50px;
  border: 1px solid #cccccc;
  position: relative;
}
.option_row select{
  position: absolute;
  top: 0;
  /*清除select的边框样式*/
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 50px;
}
.option_row option{
  height: 10px;
}
.show_user_data{
  width: 50%;
  margin-top: 5px;
}
/deep/ .van-field__label{
  color: #919191;
  width: 90px;
}
.picture-wrapper,.check-wrapper {
  align-items: center;
  margin-top: 16px;
  margin-left: 25px;
  display: flex;
  color: #919191;
  .title {
    width: 90px;
    font-size: 14px;
    display: inline-block;
  }
  .picture {
    width: 128px;
    height: 128px;
    margin-left: 20px;
  }
  .text {
    color:#cac8c8;
    font-size: 14px;
  }
  .check-group {
    .check-item {
      margin: 10px 0;
    }
  }
}

.status-scroll {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  margin-top: 10px;
  margin-bottom: -10px;
}

.title-tag {
  float: none;
  display: inline-flex;
  margin-right: 10px;
  width: auto;
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  padding: 0px 10px;
  border-radius: 13px;
  margin-bottom: 10px;
}

.title-noactive {
  background: #F5F5F5;
  color: #333333;
}

.title-active {
  background: linear-gradient(90deg, #168BF6, #4BA9FF);
  color: #ffffff;
}

.choseItem {
  border-radius: 7px;
  background: rgb(43, 152, 255, 0.1);
  border: 1px solid #2B98FF;
  padding: 10px 15px;
  margin-top: 10px;
}

.tag_name{
  //border: 1px solid #cce5ff;
  //background: #f6faff;
  //padding: 8px 17px;
  //border-radius: 8px;
  //margin-top: 10px;
  margin-bottom: 10px;
  //font-size: 14px;
  //height: 30px;
  //line-height: 32px;
  position: relative;
  cursor: pointer;
  letter-spacing: 1px;
  //color: #5c5c5c;
  //box-sizing: content-box;
  //display: flex;
  //align-items: center;
}
.close-icon{
  display: none;
  font-size: 14px;
  margin-left: 5px;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 100;
  color: #7E99B0;
}
.tag_name:hover .close-icon{
  display: block;
}

.many-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}
</style>
