<template>
  <div>
    <Popup
      v-model:show="colonization"
      position="bottom"
      closeable
      round
      :lock-scroll="true"
      :close-on-click-overlay="false">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">模板拉群</div>
      <Divider />
      <div class="steps-row">
        <div class="step">
          <div class="top" v-for="(item,index) in stepData" :key="index">
            <div class="division" v-if="index!=0" :class="[item.id<=stepIndex?'topSelect':'']"></div>
            <div class="measure" :class="[item.id<=stepIndex?'topSelect':'']">{{ index+1 }}</div>
          </div>
        </div>
        <div class="step" style="margin-top: 5px;">
          <div class="bottom" v-for="(item,index) in stepData" :key="index" :class="[item.id==stepIndex?'bottomSelect':'']">{{ item.name }}</div>
        </div>
      </div>
      <!--      选择拉群模板-->
      <div class="colonization_page" v-if="stepIndex==0">
        <div style="margin-top: 10px;" class="colonization_flex">
          <div class="row_name">动态</div>
          <van-button type="info" class="set_up_btn" @click="$refs.createTemplate.show(contactId)">创建新模板</van-button>
        </div>
        <!--        模板方法-->
        <div class="template_list">
          <van-radio-group v-model="checkedGroup">
            <div class="method_list" v-for="(item,index) in templateListData" :key="index">
              <van-list>
                <van-cell>
                  <div class="colonization_flex">
                    <div style="display: flex;">
                      <van-radio :name="index"></van-radio>
                      <div style="margin-left: 5px;font-weight: bold;">{{ item.name }}</div>
                    </div>
                    <Popover v-model:show="item.showPopover" :actions="actions" placement="bottom-end" @select="selectOption($event,item)">
                      <template #reference>
                        <div style="color: #329CFF;cursor: pointer;">编辑<van-icon name="arrow-down" /></div>
                      </template>
                    </Popover>
                  </div>
                </van-cell>
                <van-cell>
                  <div style="color: #A9A9A9;">
                    <span>共{{ item.employees.length }}名员工：</span>
                    <van-tag
                      plain
                      type="primary"
                      v-for="(obj,idx) in item.employees"
                      :key="idx"
                      size="medium"
                      style="margin-left: 10px;"
                    >{{ obj.name }}</van-tag>
                  </div>
                </van-cell>
              </van-list>
            </div>
          </van-radio-group>
        </div>
      </div>
      <!--   选择入群客户   -->
      <div class="colonization_page" v-if="stepIndex==1">
        <div class="step2-tip">请选择微信或企业微信客户，邀请客户后将会创建外部群聊</div>
        <div class="list_step2">
          <van-list>
            <van-cell v-for="(item,index) in customerArr" :key="index">
              <div style="display: flex;">
                <img :src="item.avatar" alt="" style="width: 40px;height: 40px;">
                <div style="margin-left: 5px;margin-top: 8px;">{{ item.name }} <span style="color: #24BE48;">@微信</span></div>
              </div>
            </van-cell>
          </van-list>
        </div>
        <div style="margin-top: 10px;font-size: 14px;color: #2B98FF;cursor: pointer;">
          <span @click="$refs.selectContact.show(customerArr,wxExternalUserid)">选择更多客户</span>
        </div>
      </div>
      <!--      设置群信息-->
      <div v-if="stepIndex==2" class="colonization_page">
        <div style="color: #878787;">设置群信息</div>
        <input type="text" class="group_input" placeholder="请设置群名称" v-model="roomName">
      </div>
      <!--   步骤1   按钮-->
      <div class="colonization_page" style="margin-top: 20px;margin-bottom: 20px;" v-if="stepIndex==0">
        <van-button type="primary" block @click="stepIndex++">下一步</van-button>
      </div>
      <!--      步骤2按钮-->
      <div class="colonization_page step2_btn colonization_flex" style="margin-top: 20px;margin-bottom: 20px;" v-if="stepIndex!=0">
        <van-button type="default" @click="stepIndex--">上一步</van-button>
        <van-button type="primary" @click="stepIndex++" v-if="stepIndex==1">下一步</van-button>
        <van-button type="primary" v-if="stepIndex==2" @click="startPullRoom">开始拉群</van-button>
      </div>
    </Popup>
    <!--  创建新模板  -->
    <createTemplate ref="createTemplate" @change="updatePage" />
    <!--    选择更多客户-->
    <selectContact ref="selectContact" @change="receiveCustomerData" />
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { RoomTemplatePullApi } from '@/api/roomTemplatePull'
import createTemplate from './roomTemplate/createTemplate'
import selectContact from './roomTemplate/selectContact'
import { Popup, Divider, Step, Steps, Popover, NoticeBar, Toast } from 'vant'
export default {
  components: {
    Popup, Divider, Step, Steps, Popover, createTemplate, NoticeBar, selectContact, Toast
  },
  data () {
    return {
      // 步骤条
      stepData: [
        {
          id: 0,
          name: '选择模板拉群'
        },
        {
          id: 1,
          name: '选择入群客户'
        },
        {
          id: 2,
          name: '设置群信息'
        }
      ],
      // 客户id
      contactId: '',
      colonization: false,
      activeStep: '',
      checkedGroup: 0,
      showPopover: false,
      actions: [
        { text: '修改' },
        { text: '删除' }
      ],
      stepIndex: 0,
      // 模板列表
      templateListData: [],
      // 客户列表
      customerArr: [],
      wxExternalUserid: '',
      // 群名称
      roomName: ''
    }
  },
  methods: {
    // 更新页面
    updatePage () {
      this.getTemplateData()
    },
    // 开始拉群
    startPullRoom () {
      let wxExternalUserids = ''
      const arrLength = this.customerArr.length
      this.customerArr.forEach((item, index) => {
        if (index < arrLength - 1) {
          wxExternalUserids += item.wxExternalUserid + ';'
        } else {
          wxExternalUserids += item.wxExternalUserid
        }
      })
      //  群名
      if (this.roomName == '') {
        Toast('群名不能为空')
        return false
      }
      const pullRoomData = {
        userIds: this.templateListData[this.checkedGroup].userIds,
        externalUserIds: wxExternalUserids,
        groupName: this.roomName,
        num: arrLength + this.templateListData[this.checkedGroup].employees.length
      }
      this.$router.push({ path: '/roomTemplatePull', query: { agentId: this.$route.query.agentId, pullRoomData: JSON.stringify(pullRoomData) } })
    },
    // 接收客户数据
    receiveCustomerData (e) {
      this.customerArr = []
      if (e.length) {
        e.forEach((item, index) => {
          this.customerArr[index] = JSON.parse(item)
        })
      }
    },
    // 选择选项
    selectOption (e, item) {
      if (e.text == '修改') {
        this.$refs.createTemplate.modifyShow(this.contactId, item.id)
      } else if (e.text == '删除') {
        RoomTemplatePullApi.delete({
          id: item.id
        }).then((res) => {
          Toast('删除成功')
          this.getTemplateData()
        })
      }
    },
    show (userInfo) {
      this.customerArr = []
      this.colonization = true
      this.contactId = userInfo.contactId
      this.wxExternalUserid = userInfo.wxExternalUserid
      this.customerArr.push({
        name: userInfo.name,
        avatar: userInfo.avatar,
        wxExternalUserid: userInfo.wxExternalUserid
      })
     this.getTemplateData()
    },
    //  获取建群列表内容
    getTemplateData () {
      RoomTemplatePullApi.index().then((res) => {
        this.templateListData = res.data
      })
    }
  }
}
</script>
<style scoped lang="less">
.colonization_flex{
  display: flex;
  justify-content:space-between;
}
.colonization_page{
  padding: 0 35px;
  font-size: 28px;
  color: #333;
}
.step2-tip{
  border: 1px solid #CBE7FC;
  background: #F4FAFE;
  color: #838689;
  padding: 20px 0;
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.step_name{
  font-weight: bold;
  font-size: 28px;
}
.row_name{
  color: #999;
  font-size: 14.5px;
  border-left: 7px solid #1890FF;
  height: 17.5px;
  line-height: 20px;
  padding-left: 10px;
}
.set_up_btn{
  padding: 0 20px;
  height: 25px;
  font-size: 12.5px;
}
.method_list{
  border: 1px solid #E8E8E8;
  padding: 0 10px;
  border-radius: 10px;
  margin-top: 30px;
}
.template_list{
  height: 570px;
  overflow-y: scroll;
}
/deep/ .van-step__icon--active{
  color: #42A4FF;
}
/deep/ .van-step__circle-container{
  font-size: 30px;
  color: #42A4FF;
}
/deep/ .van-step__title {
  color: #333;
}
.list_step2{
  height: 450px;
  overflow-y: scroll;
}
.step2_btn{
  button{
    width: calc(50% - 20px);
    height: 75px;
  }
}
.group_input{
  width: 100%;
  border: 1px solid #D9D9D9;
  height: 60px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 80px;
  padding-left: 10px;
}
.steps-row{
  padding: 0 20px;
  margin-bottom: 20px;
  .step{
    display: flex;
    justify-content:space-between;
    .top{
      width: 33%;
      position: relative;
      .division{
        position: absolute;
        width: calc(100% - 80px);
        height: 2px;
        background: #E8E8E8;
        top: 23px;
        right: 50%;
        margin-right: 40px;
      }
      .measure{
        width: 25px;
        height: 25px;
        margin: 0 auto;
        line-height: 25px;
        border-radius: 50%;
        background: #CCD5DE;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .topSelect{
        background: #1989FA;
      }
    }
    .bottom{
      font-size: 13px;
      width: 33%;
      text-align: center;
    }
    .bottomSelect{
      color: #1989FA;
      font-weight: bold;
    }
  }
}
</style>
