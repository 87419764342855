<template>
  <div class="index">
    <van-nav-bar
        title="客户公海"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
    />
    <div class="customer">
      <div>
        <div class="total" style="font-weight: 550;display: flex;align-items: center;justify-content: space-between">
          共{{ pages.total }}个客户
          <van-popover
              v-model="showPopover"
              trigger="click"
              placement="bottom-end"
              :actions="actions"
              @select="onSelect"
          >
            <template #reference>
              <div>{{orderBy}}</div>
            </template>
          </van-popover>
        </div>
        <div class="list">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div class="choseItem"
                 style="margin-top: 10px;border-radius: 7px;border: 1px solid transparent;"
                 v-for="(item, index) in userList" :key="index">
              <div @click="userDetails(item)" class="list-item">
                <img  class="avater" :src="item.avatar" alt="">
                <div  class="mindel">
                  <div class="item">
                    <div style="display: flex">
                      <div style="font-size: 14px;color: #000000;font-weight: bold">{{ item.name }}</div>
<!--                      <span>@微信</span>-->
                    </div>
                    <div>
                      <div v-if="item.phoneNumbers.length > 0">
                        手机号：{{item.phoneNumbers[0]}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="bottom-list" style="display: flex;justify-content: space-between;align-items: center">
                  <div>添加时间：{{item.createdAt}}</div>
                  <van-button plain type="info" style="height: 30px" @click="collectFromHighsea(item.id)">领取</van-button>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {batchCreateContactTag, collectFromHighsea, contactIndex, customerList, workRoom} from '../../api/customer'
import customerLabel from "../channelCode/components/customerLabel";
import Clipboard from 'clipboard'

export default {
  components: {
    customerLabel
  },
  data() {
    return {
      showPopover: false,
      orderBy:'时间倒序',
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '时间正序',type:'asc' }, { text: '时间倒序',type:'desc' }],
      // 列表加载
      loading: false,
      finished: false,
      page: 1,
      userList: [],
      pages: {
        perPage: '',
        total: '',
        totalPage: '',
      },
      dataLiS: {
        page: '',
        perPage: "",
        sortBy:'createdAt',
        sortType:'desc',
      },
      serList: [],
      serchT: false,
      serListp: {},

      // 客户群
      baseList: [],
      sreenw: true
    }
  },
  created() {
    this.customerBase()
  },
  methods: {
    onSelect(action) {
      console.log(action)
      this.orderBy = action.text;
      this.dataLiS.sortType = action.type
      this.userList = []
      this.page = 1
      this.onLoad()
    },
    routerPush(item) {
      this.$router.push({
        path: '/contact/detail',
        query: {
          contactId: item.id
        }
      })
    },
    routerPusht() {
      this.$router.push('/contact/screen')
    },
    // 列表加载
    onLoad() {
      this.dataLiS.page = this.page
      this.dataLiS.perPage = 10
      this.dataLiS.keyword = this.serchVale
      this.dataLiS.poolType = 1
      customerList(this.dataLiS).then(res => {
        this.userList.push(...res.data.data)
        this.pages.total = res.data.total
        if (res.data.data.length < 10) {
          this.finished = true
          this.loading = true
        } else {
          this.page++
          this.finished = false
          this.loading = false
        }
      }).catch((err) => {
        this.loading = false
        this.finished = true
      })
    },
    collectFromHighsea(id){
      let param = []
      param.push(id);
      collectFromHighsea({
        customerIds: JSON.stringify(param)
      }).then(res => {
        this.$message.success('领取成功')
        this.page = 1
        this.userList = []
        this.onLoad()
      }).catch((err) => {

      })
    },
    copy(i) {
      var clipboard = new Clipboard('.coopy')
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        //  释放内存
        clipboard.destroy()
      })
    },
    userDetails(e) {
    }
  }
}
</script>

<style lang="less" scoped>
.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .select {
    display: flex;
    align-items: center;
    background: #4376ca;
    padding: 12px 16px;
    box-sizing: border-box;
    color: #fff;
  }

  .bottom {
    .bottom-list {
      background: #fff;
      padding: 0 15px;
      font-size: 12px;
      overflow: hidden;
      padding-bottom: 10px;
    }

    .button {
      padding: 0px 12px;
      background: #fff;
      padding-bottom: 5px !important;

      div {
        font-size: 13px;
        color: #8db1db;
      }
    }

    .unset {
      max-height: unset;
    }

    border-radius: 0 0 7px 7px;
    overflow: hidden;
  }

  .customer {
    img {
      width: 22px;
      height: 22px;
    }

    padding: 20px 16px;
    padding-bottom: 51px;
  }

  .list {
    .choseItem {
      border: 1px solid #1890ff;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 10px 15px;
      border-radius: 7px 7px 0 0;

      .mindel {
        flex: 1;
        margin-left: 15px;
      }

      .avater {
        width: 50px;
        height: 50px;
      }

      .item {
        //margin-left: -87px;

        div:nth-child(1) {
          h4 {
            font-weight: bold;
            max-width: 168px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span {
            color: #5ec75d;
            margin-left: 5px;
          }
        }

        div:nth-child(2) {
          font-size: 12px;
          color: #6a6a6a;

          div:nth-child(1) {
            display: flex;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
        }
      }

      .news {
        background: #e8f0ff;
        border-radius: 50%;
      }


    }
  }

  .groups {

    padding: 0 16px 25px;

    .group {
      margin-top: 10px;
      background: #f9f9f9;
      padding: 12.5px 12.5px 6.3px 14.584px;

      .info {
        padding-bottom: 12.5px;
        border-bottom: 1.03px solid #f2f2f2;

        .col-1 {
          min-width: 0;
        }
      }

      .flex-row.justify-start {
        -webkit-box-align: start;
        align-items: flex-start;
      }

      .flex-row {
        -webkit-box-pack: start;
        -webkit-box-align: center;
        align-items: center;
        display: -webkit-box;
        display: flex;
        justify-content: flex-start;
        padding: 0;

        .col-1 {
          -webkit-box-flex: 1;
          flex: 1;

          .flex-row {
            -webkit-box-pack: start;
            -webkit-box-align: center;
            align-items: center;
            display: -webkit-box;
            display: flex;
            justify-content: flex-start;
            padding: 0;

            .flex-col {
              padding-left: 8.334px;
              -webkit-box-flex: 1;
              flex: 1;
              min-width: 0;
              padding-right: 19.793px;

              .flex-row {
                max-width: 100%;

                .bold {
                  font-size: 13.55px;
                  font-weight: 600;
                  color: #333;
                  //line-height: 1.282125rem;
                  min-width: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  word-break: break-all;
                  -webkit-box-flex: 1;
                  flex: 1;
                }

                span {
                  font-size: 13.55px;
                  color: rgba(0, 0, 0, .45);

                }
              }

              .group-owner-text {
                font-size: 12.5px;
                color: rgba(0, 0, 0, .3);

                .qw-open-data {
                  word-break: break-all;
                }
              }
            }

            .align-left {
              -webkit-box-align: start;
              align-items: flex-start;
            }

            img {
              width: 38px;
              height: 38px;
            }
          }
        }
      }

      .data {
        margin-top: 5.209px;

        .col-1 {
          span {
            color: rgba(0, 0, 0, .65);
            font-size: 12.5px;

            label {
              color: rgba(0, 0, 0, .45);
            }
          }

          span:last-child {
            margin-left: 17.709px;
          }
        }

        .mark-tag-btn {
          background: #1890ff;
          border-radius: 4px;
          font-size: 12.5px;
          color: #fff;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.042px 8.334px;
          display: inline-block;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .select-all {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 10px;
    justify-content: space-between;
    bottom: 50px;

    .length {
      color: rgba(0, 0, 0, .45);
      margin-left: -90px;
    }
  }
}
</style>
